import request from "@/utils/axios";

// 登陆
const login = (data: any) => {
  return request("/admin/login/login", "POST", data);
};
// 二维码
const wechat = (data: any) => {
  return request("/admin/wechat/getQrCode", "GET", data);
};
// 自动登录
const scanLogin = (data: any) => {
  return request("/admin/wechat/scanLogin", "POST", data);
};

const loginImg = (data: any) => {
  return request("/admin/login/info", "GET", data);
};
// 首页
const main = (data: any) => {
  return request("/admin/main/index", "GET", data);
};
// 退出登陆
const unlogin = (data: any) => {
  return request("/admin/login/logout", "POST", data);
};
// 修改头像
const updateHeadImg = (data: any) => {
  return request("/admin/login/updateHeadImg", "POST", data);
};
// 权限
const moduleMenu = (data: any) => {
  return request("/admin/login/getmenu", "GET", data);
};
// 修改密码
const newspasswd = (data: any) => {
  return request("/admin/personal/passwd", "POST", data);
};
// 修改个人资料
const informationUpdate = (data: any) => {
  return request("/admin/personal/update", "POST", data);
};
// 我的信息
const homePage = (data: any) => {
  return request("/admin/personal/info", "POST", data);
};
// 我的寄语
const teacherWord = (data: any) => {
  return request("/admin/Personal/teacherWord", "GET", data);
};
// 修改我的寄语
const updayeTeacherWord = (data: any) => {
  return request("/admin/Personal/updateTeacherWord", "POST", data);
};
// 修改老师寄语
const updayeTeacherupdateWord = (data: any) => {
  return request("/admin/basicdata/teacher/updateWord", "POST", data);
};
// 获取图片验证码
const picturecode = (data: any) => {
  return request("/admin/login/getCode", "POST", data);
};
// 校验图片验证码
const checkcode = (data: any) => {
  return request("/admin/login/checkCode", "POST", data);
};
// 手机号登陆
const loginmobile = (data: any) => {
  return request("/admin/login/loginmobile", "POST", data);
};
// 第三方跳转获取数据
const getAdminInfo = (data: any) => {
  return request("/admin/login/getAdminInfo", "GET", data);
};
// 首页
const adminInfo = (data: any) => {
  return request("/admin/index/index", "POST", data);
};
// 消息列表
const systemnoticeSelect = (data: any) => {
  return request("/admin/systemnotice/select", "POST", data);
};
// 全部已读
const systemnoticeUpdate = (data: any) => {
  return request("/admin/systemnotice/update", "GET", data);
};
// 上传文件
const uploadFile = (data: any) => {
  return request("/admin/basicdata/upload/file", "POST", data);
};
// 角色授权部门选择
const authnoneedauthorgs = (data: any) => {
  return request("/admin/auth/NoNeedAuth/orgs", "GET", data);
};
// 角色授权管理员提交
const noNeedAuthadmins = (data: any) => {
  return request("/admin/auth/noNeedAuth/admins", "GET", data);
};
// /basicdata 基础数据 /basicdata+路径
// /wisdom 智慧教室  /wisdom+路径
// 全局字典数据
const admin = (data: any) => {
  return request("/admin/dict/getAll", "GET", data);
};
// 学期管理列表
const select = (data: any) => {
  return request("/admin/basicdata/semester/select", "GET", data);
};
// 学期管理添加
const insert = (data: any) => {
  return request("/admin/basicdata/semester/insert", "POST", data);
};
// 学期管理修改
const update = (data: any) => {
  return request("/admin/basicdata/semester/update", "POST", data);
};
// 学期管理停用启用
const getUpdate = (data: any) => {
  return request("/admin/basicdata/semester/update", "PUT", data);
};
// 学期管理删除
const semesterDelete = (data: any) => {
  return request("/admin/basicdata/semester/delete", "PUT", data);
};
// 学期管理详情
const semesterDetail = (data: any) => {
  return request("/admin/basicdata/semester/detail", "GET", data);
};
// 专业列表
const major = (data: any) => {
  return request("/admin/basicdata/major/select", "GET", data);
};
// 专业分类
const zhuanyefenlei = (data: any) => {
  return request("/admin/basicdata/major-classify/select", "GET", data);
};
// 专业分类添加
const zhuanyetianjia = (data: any) => {
  return request("/admin/basicdata/major-classify/insert", "POST", data);
};
// 专业方向添加
const fangxiangtianjia = (data: any) => {
  return request("/admin/basicdata/major/insert", "POST", data);
};
// 专业分类删除
const zhuanyeshanchu = (data: any) => {
  return request("/admin/basicdata/major-classify/delete", "POST", data);
};
// 专业方向删除
const fangxiangshanchu = (data: any) => {
  return request("/admin/basicdata/major/delete", "POST", data);
};
// 专业分类修改
const zhuanyexiugai = (data: any) => {
  return request("/admin/basicdata/major-classify/update", "POST", data);
};
// 专业方向修改
const fangxaingxiugai = (data: any) => {
  return request("/admin/basicdata/major/update", "POST", data);
};
// 专业状态修改
const zhuanyezhuangtai = (data: any) => {
  return request("/admin/basicdata/major/update", "PUT", data);
};
// 专业分类详情
const zhaunyexiangqing = (data: any) => {
  return request("/admin/basicdata/major-classify/detail", "GET", data);
};
// 专业方向详情
const fangxiangxiangqing = (data: any) => {
  return request("/admin/basicdata/major/detail", "GET", data);
};
// 年级管理数据
const grade = (data: any) => {
  return request("/admin/basicdata/grade/select", "GET", data);
};
// 年级管理删除
const gradedelete = (data: any) => {
  return request("/admin/basicdata/grade/delete", "POST", data);
};
// 年级管理停用
const gradeupdate = (data: any) => {
  return request("/admin/basicdata/grade/update", "PUT", data);
};
// 年级管理添加
const gradeinsert = (data: any) => {
  return request("/admin/basicdata/grade/insert", "POST", data);
};
// 年级管理修改
const gradeupdatee = (data: any) => {
  return request("/admin/basicdata/grade/update", "POST", data);
};
// 年级管理详情
const gradedetail = (data: any) => {
  return request("/admin/basicdata/grade/detail", "GET", data);
};
// 学生列表
const studentselect = (data: any) => {
  return request("/admin/basicdata/student/select", "GET", data);
};
// 学生删除
const studentdelete = (data: any) => {
  return request("/admin/basicdata/student/delete", "POST", data);
};
// 学生状态
const studentupdate = (data: any) => {
  return request("/admin/basicdata/student/update", "PUT", data);
};
// 学生详情
const studentdetail = (data: any) => {
  return request("/admin/basicdata/student/detail", "GET", data);
};
// 学生添加
const studentinsert = (data: any) => {
  return request("/admin/basicdata/student/insert", "POST", data);
};
// 学生修改
const studentupdatee = (data: any) => {
  return request("/admin/basicdata/student/update", "POST", data);
};
// 头像上传
const uploadavatar = (data: any) => {
  return request("/admin/basicdata/upload/avatar", "POST", data);
};
// 场地教学数据
const jiaoxueshuJV = (data: any) => {
  return request("/admin/basicdata/building/select", "GET", data);
};
// 场地教学删除
const jiaoxueshanchu = (data: any) => {
  return request("/admin/basicdata/building/delete", "POST", data);
};
// 场地教学状态
const jiaoxuezhuangtai = (data: any) => {
  return request("/admin/basicdata/building/update", "PUT", data);
};
// 场地教学修改
const jiaoxuexiugai = (data: any) => {
  return request("/admin/basicdata/building/update", "POST", data);
};
// 场地教学添加
const jiaoxuetianjia = (data: any) => {
  return request("/admin/basicdata/building/insert", "POST", data);
};
// 场地教学详情
const jiaoxuexiangqing = (data: any) => {
  return request("/admin/basicdata/building/detail", "GET", data);
};
// 基础数据老师下拉框
const teacherSelectauth = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-teacher", "GET", data);
};
// 教职工信息管理
const teacherSelect = (data: any) => {
  return request("/admin/basicdata/teacher/select", "GET", data);
};
// 房间维护数据
const jiaoshishuJV = (data: any) => {
  return request("/admin/basicdata/building-room/select", "GET", data);
};
// 房间维护添加
const jiaoshitianjia = (data: any) => {
  return request("/admin/basicdata/building-room/insert", "POST", data);
};
// 房间维护状态
const jiaoshizhuangtai = (data: any) => {
  return request("/admin/basicdata/building-room/update", "PUT", data);
};
// 房间维护修改
const jiaoshixiugai = (data: any) => {
  return request("/admin/basicdata/building-room/update", "POST", data);
};
// 房间维护删除
const jiaoshishanchu = (data: any) => {
  return request("/admin/basicdata/building-room/delete", "POST", data);
};
// 房间维护详情
const jiaoshixiangqing = (data: any) => {
  return request("/admin/basicdata/building-room/detail", "GET", data);
};
// 房间维护管理员
const NoNeedAuthNoNeedAuth = (data: any) => {
  return request("/admin/basicdata/NoNeedAuth/selectAdminTeacher", "GET", data);
};
// 班级公告
const notifySelect = (data: any) => {
  return request("/admin/ecard/ecard-notify/select", "GET", data);
};
// 班级公告添加
const notifyInsert = (data: any) => {
  return request("/admin/ecard/ecard-notify/insert", "POST", data);
};
// 班级公告删除
const notifyDeletet = (data: any) => {
  return request("/admin/ecard/ecard-notify/delete", "POST", data);
};
// 班级公告修改
const notifyUpdate = (data: any) => {
  return request("/admin/ecard/ecard-notify/update", "POST", data);
};
// 教职工信息管理删除
const teacherDelete = (data: any) => {
  return request("/admin/basicdata/teacher/delete", "POST", data);
};
// 教职工管理修改状态
const teachersaveState = (data: any) => {
  return request("/admin/basicdata/teacher/saveState", "PUT", data);
};
// 教职工管理详情
const teacherDetail = (data: any) => {
  return request("/admin/basicdata/teacher/detail", "GET", data);
};
// 教职工管理添加
const teacherInsert = (data: any) => {
  return request("/admin/basicdata/teacher/insert", "POST", data);
};
// 教职工管理修改
const teacherUpdatet = (data: any) => {
  return request("/admin/basicdata/teacher/update", "POST", data);
};
// 组织机构维度数据
const zhuzhishujv = (data: any) => {
  return request("/admin/auth/admindimension/select", "GET", data);
};
// 组织机构维度添加
const zhuzhitianjia = (data: any) => {
  return request("/admin/auth/admindimension/insert", "POST", data);
};
// 组织机构维度修改
const zhuzhixiugai = (data: any) => {
  return request("/admin/auth/admindimension/update", "POST", data);
};
// 组织机构维度删除
const zhuzhishanchu = (data: any) => {
  return request("/admin/auth/admindimension/delete", "POST", data);
};
// 机构维度数据
const jigoushujv = (data: any) => {
  return request("/admin/auth/adminorg/select", "GET", data);
};
// 机构维度添加
const jigoutianjia = (data: any) => {
  return request("/admin/auth/adminorg/insert", "POST", data);
};
// 机构维度修改
const jigouxiugai = (data: any) => {
  return request("/admin/auth/adminorg/update", "POST", data);
};
// 机构维度删除
const jigoushanchu = (data: any) => {
  return request("/admin/auth/adminorg/delete", "POST", data);
};
// 登陆账户管理数据
const zhuhushuJV = (data: any) => {
  return request("/admin/auth/admin/select", "GET", data);
};
// 下拉管理员
const authnoNeedAuthadmins = (data: any) => {
  return request("/admin/auth/noNeedAuth/admins", "GET", data);
};
// 登陆账户管理修改
const zhuhuxiugai = (data: any) => {
  return request("/admin/auth/admin/update", "POST", data);
};
// 登陆账户管理删除
const zhuhushanchu = (data: any) => {
  return request("/admin/auth/admin/delete", "POST", data);
};
// 登陆账户管理状态
const zhuhuzhuangtai = (data: any) => {
  return request("/admin/auth/admin/multi", "POST", data);
};
// 登陆账户管理添加
const zhuhutianjia = (data: any) => {
  return request("/admin/auth/admin/insert", "POST", data);
};
// 登陆账户管理导入
const zhuhudaoru = (data: any) => {
  return request("/admin/auth/admin/import", "GET", data);
};

// 班级数据
const banjishuJV = (data: any) => {
  return request("/admin/basicdata/classes/select", "GET", data);
};
// 班级添加
const banjitianjia = (data: any) => {
  return request("/admin/basicdata/classes/insert", "POST", data);
};
// 班级删除
const banjishanchu = (data: any) => {
  return request("/admin/basicdata/classes/delete", "POST", data);
};
// 班级详情
const banjixiangqing = (data: any) => {
  return request("/admin/basicdata/classes/detail", "GET", data);
};
// 班级修改
const banjixiugai = (data: any) => {
  return request("/admin/basicdata/classes/update", "POST", data);
};
// 班级获取楼栋和教师二级联动数据
const banjiloudong = (data: any) => {
  return request("/admin/basicdata/building/treeSelect", "GET", data);
};
// 班级状态
const banjizhuangtai = (data: any) => {
  return request("/admin/basicdata/classes/update", "PUT", data);
};
// 班级状态
const banjizhuangtai1 = (data: any) => {
  return request("/admin/basicdata/classes/multi", "POST", data);
};
// 角色管理
const jueseguanli = (data: any) => {
  return request("/admin/auth/adminroles/select", "GET", data);
};
// 角色添加
const juesetianjia = (data: any) => {
  return request("/admin/auth/adminroles/insert", "POST", data);
};
// 角色删除
const jueseshanchu = (data: any) => {
  return request("/admin/auth/adminroles/delete", "POST", data);
};
// 角色修改
const juesesxiugai = (data: any) => {
  return request("/admin/auth/adminroles/update", "POST", data);
};
// 角色-菜单明细
const caidanmingxi = (data: any) => {
  return request("/admin/auth/adminroles/detail", "GET", data);
};
// 角色-授权用户
const shouquanyonghu = (data: any) => {
  return request("/admin/auth/adminrolesbind/select", "GET", data);
};
// 角色-授权用户删除
const shouquanyonghude = (data: any) => {
  return request("/admin/auth/adminrolesbind/delete", "POST", data);
};
// 角色-授权用户添加
const shouquanyonghuadd = (data: any) => {
  return request("/admin/auth/adminrolesbind/insert", "POST", data);
};
// 获取所有模块
const huoqusuoyou = (data: any) => {
  return request("/admin/auth/adminroles/modules", "GET", data);
};
// 获取模块下所有菜单和权限
const allpower = (data: any) => {
  return request("/admin/auth/adminroles/menus", "GET", data);
};
// 设备开关
const roomOpen = (data: any) => {
  return request("/admin/wisdom/wisdom-room/room-eq-open", "POST", data);
};
// 运行情况分析
const ketangshuJV = (data: any) => {
  return request("/admin/wisdom/wisdom/index", "GET", data);
};
// 获取课堂设备运行情况分析
const ketangshebei = (data: any) => {
  return request("/admin/wisdom/wisdom/eq-run-status", "GET", data);
};
// 获取课堂能量消耗
const ketangnengliang = (data: any) => {
  return request("/admin/wisdom/wisdom/room-quantity", "GET", data);
};
// 获取课堂排行
const ketangpaihang = (data: any) => {
  return request("/admin/wisdom/wisdom/order-quantity", "GET", data);
};
// 预警邹势分析
const ketangyujing = (data: any) => {
  return request("/admin/wisdom/wisdom/eq-run-waring", "GET", data);
};
// 获取教师面板
const jiaoshimianban = (data: any) => {
  return request("/admin/wisdom/wisdom-room/room-wisdom-panel", "GET", data);
};
// 获取设备运行状态
const jiaoshishebeiyunxing = (data: any) => {
  return request("/admin/wisdom/wisdom/eq-run-status", "GET", data);
};
// 获取环境&&甲
const jiaoshihuanjing = (data: any) => {
  return request("/admin/wisdom/wisdom/room-yangan-log", "GET", data);
};
// 能耗排行
const nenghaopaihang = (data: any) => {
  return request("/admin/wisdom/wisdom/order-quantity", "GET", data);
};
// 权限
const permission = (data: any) => {
  return request("/admin/login/permission", "GET", data);
};
// 预警信息管理
const warningLog = (data: any) => {
  return request("/admin/wisdom/wisdom/room-warning-log", "GET", data);
};
// 设备管理列表
const devicelist = (data: any) => {
  return request("/admin/wisdom/device/select", "GET", data);
};
// 策略管理列表
const celuelist = (data: any) => {
  return request("/admin/wisdom/wisdom-ploy/select", "GET", data);
};
// 策略管理详情
const celuexiangqing = (data: any) => {
  return request("/admin/wisdom/wisdom-ploy/detail", "GET", data);
};
// 策略删除接口
const celueshanchu = (data: any) => {
  return request("/admin/wisdom/wisdom-ploy/delete", "POST", data);
};
// 策略管理添加
const celuetianjia = (data: any) => {
  return request("/admin/wisdom/wisdom-ploy/insert", "POST", data);
};
// 策略管理修改
const celuexiugai = (data: any) => {
  return request("/admin/wisdom/wisdom-ploy/update", "POST", data);
};
// 项目管理列表
const warehouseprojectselect = (data: any) => {
  return request("/admin/warehouse/warehouseproject/select", "GET", data);
};
// 项目管理添加
const warehouseprojectinsert = (data: any) => {
  return request("/admin/warehouse/warehouseproject/insert", "POST", data);
};
// 项目管理修改
const warehouseprojectupdate = (data: any) => {
  return request("/admin/warehouse/warehouseproject/update", "POST", data);
};
// 项目管理删除
const warehouseprojectdelete = (data: any) => {
  return request("/admin/warehouse/warehouseproject/delete", "POST", data);
};
// 仓库管理列表
const warehouseSelect = (data: any) => {
  return request("/admin/warehouse/warehouse/select", "GET", data);
};
// 仓库管理添加
const warehouseInsert = (data: any) => {
  return request("/admin/warehouse/warehouse/insert", "POST", data);
};
// 仓库管理修改
const warehouseUpdate = (data: any) => {
  return request("/admin/warehouse/warehouse/update", "POST", data);
};
// 仓库管理删除
const warehouseDelete = (data: any) => {
  return request("/admin/warehouse/warehouse/delete", "POST", data);
};
// 获取组织机构
const zuzhijiagou = (data: any) => {
  return request("/admin/basicdata/basicselect/select-admin-org", "GET", data);
};

// 获取组织架构
const tupianshangc = (data: any) => {
  return request("/admin/basicdata/upload/avatar", "POST", data);
};
// 批量更新头像

const multiFaceImage = (data: any) => {
  return request("/admin/teacher/multiFaceImage", "POST", data);
};
// 闸机列表
const gateSelect = (data: any) => {
  return request("/admin/warehouse/warehousegate/select", "GET", data);
};
// 分类管理列表
const classIfySelect = (data: any) => {
  return request("/admin/warehouse/warehousecategory/select", "GET", data);
};
// 分类管理添加
const classIfyInsert = (data: any) => {
  return request("/admin/warehouse/warehousecategory/insert", "POST", data);
};
// 分类管理修改
const classIfyUpdate = (data: any) => {
  return request("/admin/warehouse/warehousecategory/update", "POST", data);
};
// 分类管理删除
const classIfyDelete = (data: any) => {
  return request("/admin/warehouse/warehousecategory/delete", "POST", data);
};

// 老师导入
const daorumoban = (data: any) => {
  return request("/admin/basicdata/excel/muban", "GET", data);
};
// 老师导入2
const daorumoban1 = (data: any) => {
  return request("/admin/basicdata/upload/tmpfile", "POST", data);
};
// 老师导入3
const daorumoban2 = (data: any) => {
  return request("/admin/basicdata/teacher/import", "GET", data);
};
// 班级导入3
const daorumoban3 = (data: any) => {
  return request("/admin/basicdata/major/import", "GET", data);
};
// 班级导入3
const banji3 = (data: any) => {
  return request("/admin/basicdata/classes/import", "GET", data);
};
// z专业分类
const classify = (data: any) => {
  return request("/admin/basicdata/major-classify/import", "GET", data);
};
// 学生导入3
const xuesheng3 = (data: any) => {
  return request("/admin/basicdata/student/import", "GET", data);
};
// 教室导入3
const cjiaoshi3 = (data: any) => {
  return request("/admin/basicdata/building-room/import", "GET", data);
};
// 办公楼导入3
const bangonglou3 = (data: any) => {
  return request("/admin/basicdata/building/import", "GET", data);
};
// 工具库列表
const toolSelect = (data: any) => {
  return request("/admin/warehouse/warehouselib/select", "GET", data);
};
// 工具库添加
const toolInsert = (data: any) => {
  return request("/admin/warehouse/warehouselib/insert", "POST", data);
};
// 工具库修改
const toolUpdate = (data: any) => {
  return request("/admin/warehouse/warehouselib/update", "POST", data);
};
// 工具库删除
const toolDelete = (data: any) => {
  return request("/admin/warehouse/warehouselib/delete", "POST", data);
};
// 货物管理列表
const goodsSelect = (data: any) => {
  return request("/admin/warehouse/warehousegood/select", "GET", data);
};
// 货物添加
const goodsInsert = (data: any) => {
  return request("/admin/warehouse/warehousegood/insert", "POST", data);
};
// 货物更新
const goodsUpdate = (data: any) => {
  return request("/admin/warehouse/warehousegood/update", "POST", data);
};
// 货物删除
const goodsDelete = (data: any) => {
  return request("/admin/warehouse/warehousegood/delete", "POST", data);
};
// 货物详情
const goodsDetails = (data: any) => {
  return request("/admin/warehouse/warehousegood/detail", "GET", data);
};
// 获取所有仓库
const allwarehouses = (data: any) => {
  return request("/admin/warehouse/noneedauth/warehouses", "GET", data);
};
// 获取所有闸机
const allgates = (data: any) => {
  return request("/admin/warehouse/noneedauth/gates", "GET", data);
};
// 获取所有工具柜组
const allgroups = (data: any) => {
  return request("/admin/warehouse/noneedauth/groups", "GET", data);
};
// 获取所有工具库名称
const allgoodslib = (data: any) => {
  return request("/admin/warehouse/noneedauth/goodslib", "GET", data);
};
// 闸机通行记录
const gaterecord = (data: any) => {
  return request("/admin/warehouse/warehousegatelog/select", "GET", data);
};
// 工具柜组管理
const toolguanli = (data: any) => {
  return request("/admin/warehouse/warehousecabinetgroup/select", "GET", data);
};
// 工具柜管理
const toolsguanli = (data: any) => {
  return request("/admin/warehouse/warehousecabinet/select", "GET", data);
};
// 获取所有工具柜/货架
const alltool = (data: any) => {
  return request("/admin/warehouse/noneedauth/cabinets", "GET", data);
};
// 基础数据专业分类下拉框
const classifys = (data: any) => {
  return request(
    "/admin/basicdata/no-need-auth/select-major-classify",
    "GET",
    data
  );
};
// 基础数据专业下拉框
const classifys1 = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-major", "GET", data);
};
// 基础数据年级下拉框
const selectGrade = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-grade", "GET", data);
};
// 基础数据班级下拉框
const banjijiji1 = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-classes", "GET", data);
};
// 基础数据楼栋下拉框
const selectBuilding = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-building", "GET", data);
};
// 基础数据维度
const dimension = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-dimension", "GET", data);
};
// 基础数据组织机构
const adminSelect = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-admin-org", "GET", data);
};
// 基础数据学期下拉框
const semesterSelect = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-semester", "GET", data);
};
// 申请流程管理列表
const applySelect = (data: any) => {
  return request("/admin/warehouse/warehouseworkflow/select", "GET", data);
};
// 获取所有管理员
const alladmin = (data: any) => {
  return request("/admin/warehouse/noneedauth/admins", "GET", data);
};
// 申请流程添加
const applyInsert = (data: any) => {
  return request("/admin/warehouse/warehouseworkflow/insert", "POST", data);
};
// 申请流程删除
const applyDelete = (data: any) => {
  return request("/admin/warehouse/warehouseworkflow/delete", "POST", data);
};
// 申请流程修改
const applyUpdate = (data: any) => {
  return request("/admin/warehouse/warehouseworkflow/update", "POST", data);
};
// 我的申请列表
const myapplySelect = (data: any) => {
  return request("/admin/warehouse/warehouseapply/select", "GET", data);
};
// 我的申请详情
const myapplyDetail = (data: any) => {
  return request("/admin/warehouse/warehouseapply/detail", "GET", data);
};
// 提交申请
const myapplyInsert = (data: any) => {
  return request("/admin/warehouse/warehouseapply/insert", "POST", data);
};
// 我的申请撤销
const myapplyUpdate = (data: any) => {
  return request("/admin/warehouse/warehouseapply/update", "POST", data);
};
// 我的审批 待审批列表
const myApprove = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/select", "GET", data);
};
// 我的审批 通过/驳回
const myreject = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/update", "POST", data);
};
// 审批记录
const myApprovelog = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/logs", "GET", data);
};
// 基础数据组织架构下拉框
const adminOrg = (data: any) => {
  return request("/admin/basicdata/no-need-auth/select-admin-org", "GET", data);
};
// 预警管理
const warningSelect = (data: any) => {
  return request("/admin/warehouse/warehousewarn/select", "GET", data);
};
// 退入库记录 详情
const liblogSelect = (data: any) => {
  return request("/admin/warehouse/warehousecabinetlog/select", "GET", data);
};
// 退入库记录 列表
const warehousecabinetlogstatisselect = (data: any) => {
  return request(
    "/admin/warehouse/warehousecabinetlogstatis/select",
    "GET",
    data
  );
};

// 获取已设置工作流的物品
const alllibsSelect = (data: any) => {
  return request("/admin/warehouse/warehouseworkflow/checked", "GET", data);
};
// 获取楼栋  -  教室
const getBuilding = (data: any) => {
  return request(
    "/admin/basicdata/no-need-auth/select-building-room",
    "GET",
    data
  );
};
// 获取所有物品类别
const allytype = (data: any) => {
  return request("/admin/warehouse/noneedauth/categorys", "GET", data);
};
// 根据物品获取所有库存
const allinventory = (data: any) => {
  return request("/admin/warehouse/noneedauth/inventory", "GET", data);
};
// 闸机修改接口
const gateupdate = (data: any) => {
  return request("/admin/warehouse/warehousegate/update", "POST", data);
};
// 工具柜组修改
const toolsupdate = (data: any) => {
  return request("/admin/warehouse/warehousecabinetgroup/update", "POST", data);
};
// 工具柜修改
const toolupdate = (data: any) => {
  return request("/admin/warehouse/warehousecabinet/update", "POST", data);
};
// 人脸信息
const facedetail = (data: any) => {
  return request("/admin/warehouse/warehousegate/detail", "GET", data);
};
// 账户管理重置密码
const passwsta = (data: any) => {
  return request("/admin/auth/admin/update-rset-pwd", "POST", data);
};
// 仓库分类及对应物品
const selectCtegoryGoods = (data: any) => {
  return request("/admin/warehouse/noneedauth/categoryGoods", "GET", data);
};
// 智慧教室同类型设备开关
const wisdomRoomswitch = (data: any) => {
  return request("/admin/wisdom/wisdom-room/room-eq-control", "POST", data);
};
//
const wisdomRoom = (data: any) => {
  return request("/admin/wisdom/wisdom/eq-run-status", "GET", data);
};
// // 统计
// const statistics = (data: any) => {
//   return request("/admin/warehouse/statistics/index", "GET", data);
// };
// 根据仓库，日期获取统计数据
const datestatistics = (data: any) => {
  return request("/admin/warehouse/statistics/echart", "GET", data);
};
// 获取所有在库的工具
const allintools = (data: any) => {
  return request("/admin/warehouse/noneedauth/inLibGoods", "GET", data);
};
// 智慧实训室
const trainingRoom = (data: any) => {
  return request("/admin/jump/jump", "GET", data);
};
// 智能终端人脸信息
const terminalface = (data: any) => {
  return request("/admin/warehouse/warehousecabinetgroup/detail", "GET", data);
};
// 数据分析
const statisticsIndex = (data: any) => {
  return request("/admin/warehouse/statistics/index", "GET", data);
};
// 库存预警列表
const inventoryAlert = (data: any) => {
  return request("/admin/warehouse/warehousegoodswarn/select", "GET", data);
};
// 借还记录
const circulateLog = (data: any) => {
  return request("/admin/warehouse/warehousecabinetorder/select", "GET", data);
};
// 公告管理
const noticemanage = (data: any) => {
  return request("/admin/warehouse/warehousenotify/select", "GET", data);
};
// 公告添加
const noticeinsert = (data: any) => {
  return request("/admin/warehouse/warehousenotify/insert", "POST", data);
};
// 公告修改
const noticeupdate = (data: any) => {
  return request("/admin/warehouse/warehousenotify/update", "POST", data);
};
// 公告删除
const noticedelete = (data: any) => {
  return request("/admin/warehouse/warehousenotify/delete", "POST", data);
};
//班级视频
const ecardNewsselect = (data: any) => {
  return request("/admin/ecard/ecard-video/select", "GET", data);
};
//班级视频添加
const ecardNewsadd = (data: any) => {
  return request("/admin/ecard/ecard-video/insert", "POST", data);
};
//班级视频删除
const ecardNewsadelete = (data: any) => {
  return request("/admin/ecard/ecard-video/delete", "POST", data);
};
//班级视频状态修改
const ecardNewsaupdate = (data: any) => {
  return request("/admin/ecard/ecard-video/update", "PUT", data);
};
//班级视频修改
const ecardupdate = (data: any) => {
  return request("/admin/ecard/ecard-video/update", "POST", data);
};
// 班级下拉框信息
const ecardNewsclassselect = (data: any) => {
  return request("/admin/ecard/ecard-news/select-class", "GET", data);
};
//学校视频
const schoolecardNewsselect = (data: any) => {
  return request("/admin/ecard/ecard-school-video/select", "GET", data);
};
//学校视频添加
const schoolecardNewsadd = (data: any) => {
  return request("/admin/ecard/ecard-school-video/insert", "POST", data);
};
//学校视频删除
const schoolecardNewsadelete = (data: any) => {
  return request("/admin/ecard/ecard-school-video/delete", "POST", data);
};
//学校视频状态修改
const schoolecardNewsaupdate = (data: any) => {
  return request("/admin/ecard/ecard-school-video/update", "PUT", data);
};
//学校视频修改
const schoolecardupdate = (data: any) => {
  return request("/admin/ecard/ecard-school-video/update", "POST", data);
};
// 校园风采
const schoolNewsselect = (data: any) => {
  return request("/admin/ecard/ecard-school-news/select", "GET", data);
};
// 校园风采添加
const schoolNewsadd = (data: any) => {
  return request("/admin/ecard/ecard-school-news/insert", "POST", data);
};
// 校园风采删除
const schoolNewsdelete = (data: any) => {
  return request("/admin/ecard/ecard-school-news/delete", "POST", data);
};
// 校园风采状态修改
const schoolNewsupdatesta = (data: any) => {
  return request("/admin/ecard/ecard-school-news/update", "PUT", data);
};
// 校园风采修改
const schoolNewsupdate = (data: any) => {
  return request("/admin/ecard/ecard-school-news/update", "POST", data);
};
// 班级风采
const Newsselect = (data: any) => {
  return request("/admin/ecard/ecard-news/select", "GET", data);
};
// 班级风采添加
const Newsadd = (data: any) => {
  return request("/admin/ecard/ecard-news/insert", "POST", data);
};
// 班级风采删除
const Newsdelete = (data: any) => {
  return request("/admin/ecard/ecard-news/delete", "POST", data);
};
// 班级风采状态修改
const Newsupdatesta = (data: any) => {
  return request("/admin/ecard/ecard-news/update", "PUT", data);
};
// 班级风采修改
const Newsupdate = (data: any) => {
  return request("/admin/ecard/ecard-news/update", "POST", data);
};
//优学生列表
const ecardClassselect = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/select", "GET", data);
};
//优学生列表添加
const ecardClassadd = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/insert", "POST", data);
};
// 学生下拉框
const studentSelect = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/select-student", "GET", data);
};
// 优学生列表删除
const ecardClassdelete = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/delete", "POST", data);
};
// 优学生状态更改
const ecardClassupdatesta = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/update", "PUT", data);
};
// 优学生改
const ecardClassupdate = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/update", "POST", data);
};
// 学生作品
const studentWorksselect = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/select", "GET", data);
};
// 学生作品添加
const studentWorksadd = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/insert", "POST", data);
};
//  学生作品删除
const studentWorksdelete = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/delete", "POST", data);
};
// 学生作品状态修改
const studentWorksupdatesta = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/update", "PUT", data);
};
// 学生作品修改
const studentWorksupdate = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/update", "POST", data);
};
// 图片屏保列表
const screensaverselect = (data: any) => {
  return request("/admin/ecard/ecard-screen/select", "GET", data);
};
// 图片屏保添加
const screensaverInsert = (data: any) => {
  return request("/admin/ecard/ecard-screen/insert", "POST", data);
};
// 图片屏保删除
const screensaverDelete = (data: any) => {
  return request("/admin/ecard/ecard-screen/delete", "POST", data);
};
// 图片屏保状态修改
const screensaverUpdatesta = (data: any) => {
  return request("/admin/ecard/ecard-screen/update", "PUT", data);
};
// 图片屏保上下移动状态修改
const screensaverMove = (data: any) => {
  return request("/admin/ecard/ecard-screen/move", "POST", data);
};
// 图片屏保修改
const screensaverUpdate = (data: any) => {
  return request("/admin/ecard/ecard-screen/update", "POST", data);
};
// 霸屏列表
const disableSelect = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/select", "GET", data);
};
// 霸屏添加
const disableInsert = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/insert", "POST", data);
};
// 霸屏删除
const disableDelete = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/delete", "POST", data);
};
// 霸屏修改
const disableUpdate = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/update", "POST", data);
};
// 霸屏状态修改
const disableUpdatesta = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/update", "PUT", data);
};
// 全班考勤
const attendanceSelect = (data: any) => {
  return request("/admin/edu/edu-attendance/select", "GET", data);
};
// 全班考勤查看
const attendanceDetail = (data: any) => {
  return request("/admin/edu/edu-attendance/detail", "GET", data);
};
// 全班考勤查看明细
const allendanceDetail = (data: any) => {
  return request("/admin/edu/edu-attendance-detail/select", "GET", data);
};
// 请假详情
const detailDetail = (data: any) => {
  return request("/admin/edu/edu-attendance-detail/detail", "GET", data);
};
// 请假状态
const detailUpdate = (data: any) => {
  return request("/admin/edu/edu-attendance-detail/update", "POST", data);
};
// 系统公告-列表
const systemSelect = (data: any) => {
  return request("/admin/ecard/ecard-notify/select", "GET", data);
};
// 系统公告-新增
const systemInsert = (data: any) => {
  return request("/admin/ecard/ecard-notify/insert", "POST", data);
};
// 系统公告-修改
const systemUpadate = (data: any) => {
  return request("/admin/ecard/ecard-notify/update", "POST", data);
};
// 系统公告-删除
const systemDelete = (data: any) => {
  return request("/admin/ecard/ecard-notify/delete", "POST", data);
};
// 电子班牌管理列表
const ecardselect = (data: any) => {
  return request("/admin/ecard/ecard/select", "GET", data);
};
// 电子班牌状态修改
const ecardupdateState = (data: any) => {
  return request("/admin/ecard/ecard/updateState", "POST", data);
};
// 电子班牌管理更新人脸信息
const ecardupdateface = (data: any) => {
  return request("/admin/ecard/ecard/update-face", "POST", data);
};
// 人脸信息列表
const ecartaskselect = (data: any) => {
  return request("/admin/ecard/ecard-task/select", "GET", data);
};
// 人脸信息列表删除
const ecartaskdelete = (data: any) => {
  return request("/admin/ecard/ecard-task/delete", "POST", data);
};
// 定时开关机列表
const ecarcronselect = (data: any) => {
  return request("/admin/ecard/ecard-crontab/select", "GET", data);
};
// 电子班牌楼栋下拉框列表
const ecardneedbuildingselect = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-building", "GET", data);
};
// 电子班牌房间下拉框列表
const ecardneedroomselect = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-building-room", "GET", data);
};
// 电子班牌班牌下拉框列表
const ecardneedselectecard = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-ecard", "GET", data);
};
// 定时开关机添加
const ecarcroninsert = (data: any) => {
  return request("/admin/ecard/ecard-crontab/insert", "POST", data);
};
// 定时开关机删除
const ecarcrondelete = (data: any) => {
  return request("/admin/ecard/ecard-crontab/delete", "POST", data);
};
// 定时开关机状态更改
const ecarcronupdateState = (data: any) => {
  return request("/admin/ecard/ecard-crontab/updateState", "PUT", data);
};
// 定时开关机修改
const ecarcronupdate = (data: any) => {
  return request("/admin/ecard/ecard-crontab/update", "POST", data);
};
// 人脸同步列表
const ecartaskselectface = (data: any) => {
  return request("/admin/ecard/ecard-task/select-admin-face", "GET", data);
};
// 电子班牌班级下拉框
const ecarauthselectclasses = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-classes", "GET", data);
};
// 人脸同步至电子班牌
const ecartaskinsertface = (data: any) => {
  return request("/admin/ecard/ecard-task/insert-admin-face", "POST", data);
};
// 我的课表
const coursetableselect = (data: any) => {
  return request("/admin/edu/edu-course-table/select", "GET", data);
};
// 获取当前学期 作息时间设置.列表
const coursetleselect = (data: any) => {
  return request(
    "/admin/edu/no-need-auth/select-now-sem-table-set",
    "GET",
    data
  );
};
// 班级课表过去当日课表
const coursetleselectday = (data: any) => {
  return request("/admin/edu/edu-course-table/select-day", "GET", data);
};
// 课表详情
const coursetledetail = (data: any) => {
  return request("/admin/edu/edu-course-table/detail", "GET", data);
};
// 课程管理列表
const courSelect = (data: any) => {
  return request("/admin/edu/edu-course/select", "GET", data);
};
// 课程管理添加
const courInsert = (data: any) => {
  return request("/admin/edu/edu-course/insert", "POST", data);
};
// 课程管理删除
const courDelete = (data: any) => {
  return request("/admin/edu/edu-course/delete", "POST", data);
};
// 课程管理修改
const courUpdate = (data: any) => {
  return request("/admin/edu/edu-course/update", "POST", data);
};
// 学科下拉框
const authsubject = (data: any) => {
  return request("/admin/edu/no-need-auth/select-subject", "GET", data);
};
// 教务课表当前周
const educoursetermInfo = (data: any) => {
  return request("/admin/edu/edu-course-table/termInfo", "GET", data);
};
// 教务学期下拉框
const eduAuthsemester = (data: any) => {
  return request("/admin/edu/no-need-auth/select-semester", "GET", data);
};
// 教务班级下拉框
const eduAuthclasses = (data: any) => {
  return request("/admin/edu/no-need-auth/select-classes", "GET", data);
};
// 教务组织机构下拉框
const eduAuthadminorg = (data: any) => {
  return request("/admin/edu/no-need-auth/select-admin-org", "GET", data);
};
// 教务教室下拉框
const eduAuthteacher = (data: any) => {
  return request("/admin/edu/no-need-auth/select-teacher", "GET", data);
};
// 教务年级下拉框
const eduAuthgrade = (data: any) => {
  return request("/admin/edu/no-need-auth/select-grade", "GET", data);
};
// 排课列表
const eduCourseselect = (data: any) => {
  return request("/admin/edu/edu-course-table/select-class", "GET", data);
};
//班级课表
const eduCoursetableselect = (data: any) => {
  return request("/admin/edu/edu-course-table/select", "GET", data);
};
// 教务班级下拉框
const eduneedAuthclasses = (data: any) => {
  return request("/admin/edu/no-need-auth/select-classes", "GET", data);
};
// 教务课程下拉框
const eduneedAuthcourse = (data: any) => {
  return request("/admin/edu/no-need-auth/select-course", "GET", data);
};
// 教务教室树结构下拉框
const eduneedroomtree = (data: any) => {
  return request(
    "/admin/edu/no-need-auth/select-building-room-tree",
    "GET",
    data
  );
};
// 教务 班主任签到
const EduTeacherSignselect = (data: any) => {
  return request("/admin/edu/EduTeacherSign/select", "GET", data);
};
// 课表添加
const eduCoursetableinsert = (data: any) => {
  return request("/admin/edu/edu-course-table-plan/insert", "POST", data);
};
// 作息时间列表
const edutimetableselect = (data: any) => {
  return request("/admin/edu/edu-time-table-set/select", "GET", data);
};
// 作息时间修改
const edutimetableupdate = (data: any) => {
  return request("/admin/edu/edu-time-table-set/update", "POST", data);
};
// 作息节次列表
const edutimetableselectset = (data: any) => {
  return request("/admin/edu/edu-time-table/select", "GET", data);
};
// 作息节次详情
const edutimetablesinsert = (data: any) => {
  return request("/admin/edu/edu-time-table/insert", "POST", data);
};
// 作息节次修改
const edutimetablesupdate = (data: any) => {
  return request("/admin/edu/edu-course-table/update", "POST", data);
};
// 请假管理列表
const edustudentleaveselect = (data: any) => {
  return request("/admin/edu/dorm-student-leave/select", "GET", data);
};
//请假管理审核记录
const edustudentleavlogselect = (data: any) => {
  return request("/admin/edu/dorm-student-leave-log/select", "GET", data);
};
//请假管理审核记录
const edustudentleaveupdate = (data: any) => {
  return request("/admin/edu/dorm-student-leave/update", "POST", data);
};
// 学科管理列表
const eduedusubjectselect = (data: any) => {
  return request("/admin/edu/edu-subject/select", "GET", data);
};
// 学科管理删除
const eduedusubjectdelete = (data: any) => {
  return request("/admin/edu/edu-subject/delete", "POST", data);
};
// 学科管理添加
const eduedusubjectinsert = (data: any) => {
  return request("/admin/edu/edu-subject/insert", "POST", data);
};
// 学科管理修改
const eduedusubjectupdate = (data: any) => {
  return request("/admin/edu/edu-subject/update", "POST", data);
};
// 实训室报警记录
const trainingdevicewarnlogselect = (data: any) => {
  return request("/admin/training/trainingdevicewarnlog/select", "GET", data);
};
// 实训室实训室下拉框
const trainingnoneedauthselect = (data: any) => {
  return request("/admin/training/noneedauth/trainings", "GET", data);
};
// 实训室教室下拉框
const trainingroomsselect = (data: any) => {
  return request("/admin/training/noneedauth/rooms", "GET", data);
};
// 实训室管理列表
const trainingtrainingselect = (data: any) => {
  return request("/admin/training/training/select", "GET", data);
};
// 实训室管理添加
const trainingtraininginsert = (data: any) => {
  return request("/admin/training/training/insert", "POST", data);
};
// 实训室管理删除
const trainingtrainingdelete = (data: any) => {
  return request("/admin/training/training/delete", "POST", data);
};
// 实训室管理修改
const trainingtrainingupdate = (data: any) => {
  return request("/admin/training/training/update", "POST", data);
};
// 实训室设备管理列表
const trainingtrainingdeviceselect = (data: any) => {
  return request("/admin/training/trainingdevice/select", "GET", data);
};
// 实训室设备管理添加
const trainingtrainingdeviceinsert = (data: any) => {
  return request("/admin/training/trainingdevice/insert", "POST", data);
};
// 实训室设备管理删除
const trainingtrainingdevicedelete = (data: any) => {
  return request("/admin/training/trainingdevice/delete", "POST", data);
};
// 实训室设备管理删除
const trainingtrainingdeviceupdate = (data: any) => {
  return request("/admin/training/trainingdevice/update", "POST", data);
};
// 实训室设备管理详情
const trainingtrainingdevicedetail = (data: any) => {
  return request("/admin/training/trainingdevice/detail", "GET", data);
};
// 实训室设备管理详情模块状态
const trainingtrainingdevicegetDeviceStatus = (data: any) => {
  return request("/admin/training/trainingdevice/getDeviceStatus", "GET", data);
};
// 实训室设备管理详情模块状态修改
const trainingtrainingdevicesetDeviceStatus = (data: any) => {
  return request("/admin/training/trainingdevice/setDeviceStatus", "GET", data);
};
// 归寝宿管
const noneedauthadmins = (data: any) => {
  return request("/admin/dorm/noneedauth/admins", "GET", data);
};
// 归寝楼栋
const dormdormBuildings = (data: any) => {
  return request("/admin/dorm/noneedauth/dormBuildings", "GET", data);
};
// 归寝宿舍类型
const dormnoneedauthdormType = (data: any) => {
  return request("/admin/dorm/noneedauth/dormType", "GET", data);
};
// 归寝宿舍管理楼栋信息列表
const dormbuildingselect = (data: any) => {
  return request("/admin/dorm/dormbuilding/select", "GET", data);
};
// 归寝宿舍管理楼栋信息添加
const dormbuildinginsert = (data: any) => {
  return request("/admin/dorm/dormbuilding/insert", "POST", data);
};
// 归寝宿舍管理楼栋信息删除
const dormbuildingdelete = (data: any) => {
  return request("/admin/dorm/dormbuilding/delete", "POST", data);
};
// 归寝宿舍管理楼栋信息修改
const dormbuildingupdate = (data: any) => {
  return request("/admin/dorm/dormbuilding/update", "POST", data);
};
// 归寝宿舍管理寝室信息列表
const dormdormselect = (data: any) => {
  return request("/admin/dorm/dorm/select", "GET", data);
};
// 归寝宿舍管理寝室信息添加
const dormdorminsert = (data: any) => {
  return request("/admin/dorm/dorm/insert", "POST", data);
};
// 归寝宿舍管理寝室信息删除
const dormdormdelete = (data: any) => {
  return request("/admin/dorm/dorm/delete", "POST", data);
};
// 归寝宿舍管理寝室信息修改
const dormdormupdate = (data: any) => {
  return request("/admin/dorm/dorm/update", "POST", data);
};
// 归寝宿舍管理寝室信息列表
const dormdormtypeselect = (data: any) => {
  return request("/admin/dorm/dormtype/select", "GET", data);
};
// 归寝宿舍管理寝室信息添加
const dormdormtypeinsert = (data: any) => {
  return request("/admin/dorm/dormtype/insert", "POST", data);
};
// 归寝宿舍管理寝室信息删除
const dormdormtypedelete = (data: any) => {
  return request("/admin/dorm/dormtype/delete", "POST", data);
};
// 归寝宿舍管理寝室信息修改
const dormdormtypeupdate = (data: any) => {
  return request("/admin/dorm/dormtype/update", "POST", data);
};
// 归寝宿舍管理公告列表
const dormdormnotifyselect = (data: any) => {
  return request("/admin/dorm/dormnotify/select", "GET", data);
};
// 归寝宿舍管理公告添加
const dormdormnotifyinsert = (data: any) => {
  return request("/admin/dorm/dormnotify/insert", "POST", data);
};
// 归寝宿舍管理公告修改
const dormdormnotifyupdate = (data: any) => {
  return request("/admin/dorm/dormnotify/update", "POST", data);
};
// 归寝宿舍管理公告删除
const dormdormnotifydelete = (data: any) => {
  return request("/admin/dorm/dormnotify/delete", "POST", data);
};
// 归寝设备管理放行机管理列表
const dormdevicereleasedelete = (data: any) => {
  return request("/admin/dorm/dormdevicerelease/select", "GET", data);
};
// 归寝设备管理放行机管理修改
const dormdevicereleaseupdate = (data: any) => {
  return request("/admin/dorm/dormdevicerelease/update", "POST", data);
};
// 归寝设备管理闸机管理列表
const dormdormgateselect = (data: any) => {
  return request("/admin/dorm/dormgate/select", "GET", data);
};
// 归寝设备寝室床位管理列表
const dordormbedselect = (data: any) => {
  return request("/admin/dorm/dormbed/select", "GET", data);
};
// 归寝设备寝室床位管理添加
const dordormbedinsert = (data: any) => {
  return request("/admin/dorm/dormbed/insert", "GET", data);
};
// 归寝设备寝室床位管理删除
const dordormbeddelete = (data: any) => {
  return request("/admin/dorm/dormbed/delete", "GET", data);
};
// 归寝年级
const dormnoneedauthgrades = (data: any) => {
  return request("/admin/dorm/noneedauth/grades", "GET", data);
};
// 归寝班级
const dormnoneedauthclasses = (data: any) => {
  return request("/admin/dorm/noneedauth/classes", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位one
const dormdormarrangeselect = (data: any) => {
  return request("/admin/dorm/dormarrange/select", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位two  获取寝室学生管理宿舍列表
const dormnoneedauthdorms = (data: any) => {
  return request("/admin/dorm/noneedauth/dorms", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位two提交
const dormnoneedauthinsert = (data: any) => {
  return request("/admin/dorm/dormarrange/insert", "POST", data);
};
// 归寝学生管理寝室安排单个安排床位
const dormdormarrangestudents = (data: any) => {
  return request("/admin/dorm/dormarrange/students", "GET", data);
};
// 归寝学生管理寝室安排单个安排床位提交
const dormdormarrangestudentSave = (data: any) => {
  return request("/admin/dorm/dormarrange/studentSave", "POST", data);
};
// 归寝学生管理班级退勤列表
const dormdormquitselect = (data: any) => {
  return request("/admin/dorm/dormquit/select", "GET", data);
};
// 归寝学生管理班级退勤退勤
const dormdormquitdelete = (data: any) => {
  return request("/admin/dorm/dormquit/delete", "POST", data);
};
// 归寝学生管理寝室明细
const dormdormmembeselectr = (data: any) => {
  return request("/admin/dorm/dormmember/select", "GET", data);
};
// 归寝学生管理寝室统计归寝列表
const dormDormAttendanceselect = (data: any) => {
  return request("/admin/dorm/DormAttendance/select", "GET", data);
};
// 归寝学生管理寝室统计归寝明细
const dormDormAttendancedetail = (data: any) => {
  return request("/admin/dorm/DormAttendance/detail", "GET", data);
};
// 教职工考勤 上下班打卡管理 列表
const kqkqworkruleselect = (data: any) => {
  return request("/admin/kq/kqworkrule/select", "GET", data);
};
// 教职工考勤 上下班打卡管理 删除
const kqkqworkruledelete = (data: any) => {
  return request("/admin/kq/kqworkrule/delete", "GET", data);
};
// 教职工考勤 上下班打卡管理 添加
const kqkqworkruleinsert = (data: any) => {
  return request("/admin/kq/kqworkrule/insert", "POST", data);
};
// 教职工考勤 上下班打卡管理 修改
const kqkqworkruleupdate = (data: any) => {
  return request("/admin/kq/kqworkrule/update", "POST", data);
};
// 教职工考勤 上下班打卡管理 状态
const kqkqworkrulestatus = (data: any) => {
  return request("/admin/kq/kqworkrule/status", "GET", data);
};
// 教职工考勤 考勤设备管理列表
const kqeqselect = (data: any) => {
  return request("/admin/kq/kqeq/select", "GET", data);
};
// 教职工考勤 所有设备
const kqnoneedautheq = (data: any) => {
  return request("/admin/kq/noneedauth/eq", "GET", data);
};
// 教职工考勤 机构成员
const kqnoneedauthorgs = (data: any) => {
  return request("/admin/kq/noneedauth/orgs", "GET", data);
};
// 教职工考勤 考勤设备管理修改
const kqequpdate = (data: any) => {
  return request("/admin/kq/kqeq/update", "POST", data);
};
// 教职工考勤 请假记录
const kqleaveapplyselect = (data: any) => {
  return request("/admin/kq/kqleaveapply/select", "GET", data);
};
// 获取所有组织机构及成员
const allorgsselect = (data: any) => {
  return request("/admin/kq/noneedauth/orgs", "GET", data);
};
// 教职工考勤 补卡记录
const replacementlog = (data: any) => {
  return request("/admin/kq/kquncardapply/select", "GET", data);
};
// 教职工考勤 我的请假列表
const myleaveselect = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/select", "GET", data);
};
// 教职工考勤 我的请假添加
const myleaveinsert = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/insert", "POST", data);
};
// 教职工考勤 我的请假撤销
const myleavestatus = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/status", "GET", data);
};
// 教职工考勤 请假规则列表
const kqleaveruleselect = (data: any) => {
  return request("/admin/kq/kqleaverule/select", "GET", data);
};
// 教职工考勤 请假规则状态修改
const kqleaverulestatus = (data: any) => {
  return request("/admin/kq/kqleaverule/status", "GET", data);
};
// 教职工考勤 请假规则状态删除
const kqleaveruledelete = (data: any) => {
  return request("/admin/kq/kqleaverule/delete", "GET", data);
};
// 教职工考勤 我的请假审核列表
const kqleaveapplymyauditselect = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/select", "GET", data);
};
// 教职工考勤 我的请假审核
const kqleaveapplymyauditstatus = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/status", "GET", data);
};
// 教职工考勤 我的请假审核记录
const kqleaveapplymyauditauditList = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/auditList", "GET", data);
};
// 教职工考勤 补卡规则列表
const kquncardruleselect = (data: any) => {
  return request("/admin/kq/kquncardrule/select", "GET", data);
};
// 教职工考勤 补卡规则状态修改
const kquncardrulestatus = (data: any) => {
  return request("/admin/kq/kquncardrule/status", "GET", data);
};
// 教职工考勤 补卡规则状态删除
const kquncardruledelete = (data: any) => {
  return request("/admin/kq/kquncardrule/delete", "GET", data);
};
// 教职工考勤 我的补卡列表
const kquncardapplymyselect = (data: any) => {
  return request("/admin/kq/kquncardapplymy/select", "GET", data);
};
// 教职工考勤 申请补卡
const kquncardapplymyinsert = (data: any) => {
  return request("/admin/kq/kquncardapplymy/insert", "POST", data);
};
// 教职工考勤 撤销补卡
const kquncardapplymydelete = (data: any) => {
  return request("/admin/kq/kquncardapplymy/status", "GET", data);
};
// 教职工考勤 我的补卡审核
const kquncardapplymyauditselect = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/select", "GET", data);
};
// 教职工考勤 我的补卡审核 通过/驳回
const kquncardapplymyauditupdate = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/status", "GET", data);
};
// 审核记录
const kquncardapplymyauditdetail = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/auditList", "GET", data);
};
// 请假规则添加
const kqleaveruleinsert = (data: any) => {
  return request("/admin/kq/kqleaverule/insert", "POST", data);
};
// 请假规则修改
const kqleaveruleupdate = (data: any) => {
  return request("/admin/kq/kqleaverule/update", "POST", data);
};
//
const kqnoneedauthadmins = (data: any) => {
  return request("/admin/kq/noneedauth/admins", "GET", data);
};
// 每日考勤统计列表
const kqstatisticsdayslelct = (data: any) => {
  return request("/admin/kq/kqstatisticsday/select", "GET", data);
};
// 数据分析-考勤数据总览
const kqkqstatistics = (data: any) => {
  return request("/admin/kq/kqstatistics/all", "GET", data);
};
// 数据分析-教职工考勤情况
const kqkqstatisticsdetail = (data: any) => {
  return request("/admin/kq/kqstatistics/detail", "GET", data);
};
// 数据分析-月度考勤率
const kqkqstatisticsmounth = (data: any) => {
  return request("/admin/kq/kqstatistics/kqMonth", "GET", data);
};
// 数据分析-部门考勤数据
const kqkqstatisticsdep = (data: any) => {
  return request("/admin/kq/kqstatistics/dep", "GET", data);
};
// 数据分析-教职工考勤排行
const kqkqstatisticstop = (data: any) => {
  return request("/admin/kq/kqstatistics/top", "GET", data);
};
// 月考勤统计-总统计
const kqstatisticsmonthselect = (data: any) => {
  return request("/admin/kq/kqstatisticsmonth/select", "GET", data);
};
// 月考勤统计-明细
const kqstatisticsmonthdetail = (data: any) => {
  return request("/admin/kq/kqstatisticsmonth/detail", "GET", data);
};
// 教职工 分级获取子部门及成员
const noneedauthtree = (data: any) => {
  return request("/admin/kq/noneedauth/tree", "GET", data);
};
// 归寝管理 放行机位置管理列表
const dormdevicepositionselect = (data: any) => {
  return request("/admin/dorm/dormdeviceposition/select", "GET", data);
};
// 归寝管理 放行机位置管理添加
const dormdevicepositioninsert = (data: any) => {
  return request("/admin/dorm/dormdeviceposition/insert", "POST", data);
};
// 归寝管理 放行机位置管理删除
const dormdevicepositiondelete = (data: any) => {
  return request("/admin/dorm/dormdeviceposition/delete", "POST", data);
};
// 归寝管理 放行机位置管理修改
const dormdevicepositionupdate = (data: any) => {
  return request("/admin/dorm/dormdeviceposition/update", "POST", data);
};
// 归寝管理 放行机位置下拉
const noneedauthdevicePosition = (data: any) => {
  return request("/admin/dorm/noneedauth/devicePosition", "GET", data);
};
// 归寝管理 闸机
const noneedauthqcGates = (data: any) => {
  return request("/admin/dorm/noneedauth/qcGates", "GET", data);
};
// 归寝管理 首页全部数据
const allpagehomeselect = (data: any) => {
  return request("/admin/dorm/statistics/select", "POST", data);
};
// 归寝管理 考勤统计
const kqselectdatalist = (data: any) => {
  return request("/admin/dorm/statistics/echart", "GET", data);
};
// 归寝管理 归寝统计
const gqselectdatalist = (data: any) => {
  return request("/admin/dorm/statistics/staticDorm", "GET", data);
};
// 归寝管理 未到齐宿舍
const outdormselect = (data: any) => {
  return request("/admin/dorm/statistics/outDorm", "GET", data);
};
// 归寝管理 请假学生明细
const statisticsleaveStudents = (data: any) => {
  return request("/admin/dorm/statistics/leaveStudents", "GET", data);
};
// 归寝管理 设备管理列表
const dormGateOldselect = (data: any) => {
  return request("/admin/dorm/dormGateOld/select", "GET", data);
};
// 归寝管理 设备管理列表修改
const dormGateOldupdate = (data: any) => {
  return request("/admin/dorm/dormGateOld/update", "POST", data);
};
// 归寝管理 设备管理列表定时开关门
const dormGateOlddoor = (data: any) => {
  return request("/admin/dorm/dormGateOld/door", "GET", data);
};
// 归寝管理 设备管理列表一键开门
const dormGateOldopenAll = (data: any) => {
  return request("/admin/dorm/dormGateOld/openAll", "POST", data);
};
// 归寝管理 设备管理列表一键关门
const dormGateOldcloseAlll = (data: any) => {
  return request("/admin/dorm/dormGateOld/closeAll", "POST", data);
};
// 归寝管理 设备管理人脸同步列表
const dormGateOldmembers = (data: any) => {
  return request("/admin/dorm/dormGateOld/members", "GET", data);
};
// 归寝管理 设备管理人脸同步同步人脸
const dormGateOldsync = (data: any) => {
  return request("/admin/dorm/dormGateOld/sync", "POST", data);
};
// 归寝管理 设备管理人脸信息列表
const dormGateOldgateImage = (data: any) => {
  return request("/admin/dorm/dormGateOld/gateImage", "GET", data);
};
// 归寝管理 设备管理人脸信息列表删除
const dormGateOlddelImage = (data: any) => {
  return request("/admin/dorm/dormGateOld/delImage", "POST", data);
};
// 归寝管理 定时开门设置列表
const dormGateOldcrontabList = (data: any) => {
  return request("/admin/dorm/dormGateOld/crontabList", "GET", data);
};
// 归寝管理 定时开门设置列表添加
const dormGateOldcrontabCreate = (data: any) => {
  return request("/admin/dorm/dormGateOld/crontabCreate", "POST", data);
};
// 归寝管理 定时开门设置列表修改
const dormGateOldcrontabUpdate = (data: any) => {
  return request("/admin/dorm/dormGateOld/crontabUpdate", "POST", data);
};
// 归寝管理 定时开门设置列表删除
const dormGateOldcrontabDelete = (data: any) => {
  return request("/admin/dorm/dormGateOld/crontabDelete", "GET", data);
};
// 归寝管理 定时开门设置列表按钮
const dormGateOldcrontabStatus = (data: any) => {
  return request("/admin/dorm/dormGateOld/crontabStatus", "GET", data);
};
// 电子班牌
const ecardtaskdelete = (data: any) => {
  return request("/admin/ecard/ecard-task/delete", "POST", data);
};
// 仓储部门组织机构下拉
const noneedauthorgs = (data: any) => {
  return request("/admin/warehouse/noneedauth/orgs", "GET", data);
};
// 仓储项目拉
const noneedauthprojects = (data: any) => {
  return request("/admin/warehouse/noneedauth/projects", "GET", data);
};
// 仓储项目拉
const noneedauthprojecting = (data: any) => {
  return request("/admin/warehouse/noneedauth/projecting", "GET", data);
};
// 智慧教室  获取所有设备
const noneedauthdevices = (data: any) => {
  return request("/admin/tuya/noneedauth/devices", "GET", data);
};
// 智慧教室  设备分类
const noneedauthcategorys = (data: any) => {
  return request("/admin/tuya/noneedauth/categorys", "GET", data);
};
// 智慧教室  楼栋下拉数据
const noneedauthbuildings = (data: any) => {
  return request("/admin/tuya/noneedauth/buildings", "GET", data);
};
// 智慧教室  涂鸦房间下拉数据
const noneedauthtuyaRooms = (data: any) => {
  return request("/admin/tuya/noneedauth/tuyaRooms", "GET", data);
};
// 智慧教室 信息管理
const tuyahomeroomselect = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/select", "GET", data);
};
// 智慧教室 预警信息统计列表
const tuyawarnselect = (data: any) => {
  return request("/admin/tuya/tuyawarn/select", "GET", data);
};
// 智慧教室 预警规则管理列表
const tuyawarnsetselect = (data: any) => {
  return request("/admin/tuya/tuyawarnset/select", "GET", data);
};
// 智慧教室 预警信息统计添加
const tuyawarnsetinsert = (data: any) => {
  return request("/admin/tuya/tuyawarnset/insert", "POST", data);
};
// 智慧教室 预警信息统计删除
const tuyawarnsetdelete = (data: any) => {
  return request("/admin/tuya/tuyawarnset/delete", "POST", data);
};
// 智慧教室 预警信息统计删除
const tuyawarnsetupdate = (data: any) => {
  return request("/admin/tuya/tuyawarnset/update", "POST", data);
};
// 智慧教室  设备管理列表
const tuyadeviceselect = (data: any) => {
  return request("/admin/tuya/tuyadevice/select", "GET", data);
};
// 智慧教室  设备管理统计
const tuyadevicestatistics = (data: any) => {
  return request("/admin/tuya/tuyadevice/statistics", "POST", data);
};
// 智慧教室  设备管理详情
const tuyahomeroomdetail = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/detail", "GET", data);
};
// 智慧教室  设备管理详情 设备明细
const tuyahomeroomlist = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/list", "GET", data);
};
// 智慧教室  设备管理详情 设备开关
const tuyahomeroomswitch = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/switch", "GET", data);
};
// 获取班级组织及机构列表
const selectClassesOrg = (data: any) => {
  return request("/admin/edu/no-need-auth/selectClassesOrg", "GET", data);
};
// 智慧教室  策略管理列表
const tuyasencerulepoolselect = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/select", "GET", data);
};
// 智慧教室  策略管理添加-定时联动
const tuyasencerulepoolinsert = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/insert", "POST", data);
};
// 智慧教室  策略管理修改-定时联动
const tuyasencerulepoolupdate = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/update", "POST", data);
};
const tuyasencerulepooldelete = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/delete", "POST", data);
};
// 智慧教室  执行管理列表
const tuyasencerulepoolactionselect = (data: any) => {
  return request("/admin/tuya/tuyasencerulepoolaction/select", "GET", data);
};
// 智慧教室  执行管理执行添加
const tuyasencerulepoolactioninsert = (data: any) => {
  return request("/admin/tuya/tuyasencerulepoolaction/insert", "POST", data);
};
// 智慧教室  执行管理房间列表
const tuyasencerulepoolactionrooms = (data: any) => {
  return request("/admin/tuya/tuyasencerulepoolaction/rooms", "GET", data);
};
// 智慧教室  执行管理房间启用禁用
const tuyasencerulepoolactionupdate = (data: any) => {
  return request("/admin/tuya/tuyasencerulepoolaction/update", "POST", data);
};
// 智慧教室  执行管理房间启用删除
const tuyasencerulepoolactiondelete = (data: any) => {
  return request("/admin/tuya/tuyasencerulepoolaction/delete", "POST", data);
};
// 智慧教室 条件设备下拉
const noneedauthconditionDevices = (data: any) => {
  return request("/admin/tuya/noneedauth/conditionDevices", "POST", data);
};
// 智慧教室 统计总数
const statisticscount = (data: any) => {
  return request("/admin/tuya/Statistics/count", "GET", data);
};
// 智慧教室 设备类型状态
const statisticsstatus = (data: any) => {
  return request("/admin/tuya/Statistics/deviceStatus", "GET", data);
};
// 智慧教室 预警分析
const statisticswarn = (data: any) => {
  return request("/admin/tuya/Statistics/warns", "GET", data);
};
// 智慧教室 能耗排行榜10
const statistictop = (data: any) => {
  return request("/admin/tuya/Statistics/tops", "GET", data);
};
// 智慧教室 能耗趋势分析
const statisticquantity = (data: any) => {
  return request("/admin/tuya/Statistics/quantity", "GET", data);
};
// 智慧教室 设备耗电量分析
const statisticquantitydevice = (data: any) => {
  return request("/admin/tuya/Statistics/deviceQuantity", "GET", data);
};
// 仓储 出库统计
const warehousecabinetordercountselect = (data: any) => {
  return request(
    "/admin/warehouse/warehousecabinetordercount/select",
    "GET",
    data
  );
};
// 智慧实训室 实训室管理列表
const generalTrainingselect = (data: any) => {
  return request("/admin/generalTraining/generalTraining/select", "GET", data);
};
// 智慧实训室 实训室管理添加
const generalTraininginsert = (data: any) => {
  return request("/admin/generalTraining/generalTraining/insert", "POST", data);
};
// 智慧实训室 实训室管理修改
const generalTrainingupdate = (data: any) => {
  return request("/admin/generalTraining/generalTraining/update", "POST", data);
};
// 智慧实训室 实训室管理删除
const generalTrainingdelete = (data: any) => {
  return request("/admin/generalTraining/generalTraining/delete", "POST", data);
};
// 智慧实训室 设备管理列表
const generalTrainingDeviceselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/select",
    "GET",
    data
  );
};
// 智慧实训室 设备管理添加
const generalTrainingDeviceinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/insert",
    "POST",
    data
  );
};
// 智慧实训室 设备管理修改
const generalTrainingDeviceupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/update",
    "POST",
    data
  );
};
// 智慧实训室 设备管理删除
const generalTrainingDevicedelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/delete",
    "POST",
    data
  );
};
// 智慧实训室 实训室类型列表
const generalTrainingTypeselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingType/select",
    "GET",
    data
  );
};
// 智慧实训室 实训室类型列表下拉（不建权）
const generalTrainingnoneedauthtrainingType = (data: any) => {
  return request("/admin/generalTraining/noneedauth/trainingType", "GET", data);
};

// 智慧实训室 实训室类型添加
const generalTrainingTypeinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingType/insert",
    "POST",
    data
  );
};
// 智慧实训室 实训室类型修改
const generalTrainingTypeupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingType/update",
    "POST",
    data
  );
};
// 智慧实训室 实训室类型删除
const generalTrainingTypedelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingType/delete",
    "POST",
    data
  );
};
// 智慧实训室 设备类型列表
const generalTrainingDeviceCateselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceCate/select",
    "GET",
    data
  );
};
// 智慧实训室 设备类型添加
const generalTrainingDeviceCateinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceCate/insert",
    "POST",
    data
  );
};
// 智慧实训室 设备类型修改
const generalTrainingDeviceCateupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceCate/update",
    "POST",
    data
  );
};
// 智慧实训室 设备类型修改
const generalTrainingDeviceCatedelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceCate/delete",
    "POST",
    data
  );
};
// 智慧实训室 保养类型列表
const generalTrainingMaintenanceTypeselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceType/select",
    "GET",
    data
  );
};
// 智慧实训室 保养类型添加
const generalTrainingMaintenanceTypeinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceType/insert",
    "POST",
    data
  );
};
// 智慧实训室 保养类型修改
const generalTrainingMaintenanceTypeupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceType/update",
    "POST",
    data
  );
};
// 智慧实训室 保养类型删除
const generalTrainingMaintenanceTypedelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceType/delete",
    "POST",
    data
  );
};
// 智慧实训室 保养计划列表
const generalTrainingPlanselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingPlan/select",
    "GET",
    data
  );
};
// 智慧实训室 保养计划添加
const generalTrainingPlaninsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingPlan/insert",
    "POST",
    data
  );
};
// 智慧实训室 保养计划修改
const generalTrainingPlanupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingPlan/update",
    "POST",
    data
  );
};
// 智慧实训室 保养计划删除
const generalTrainingPlandelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingPlan/delete",
    "POST",
    data
  );
};
// 智慧实训室 保养项目列表
const generalTrainingMaintenanceNodeselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceNode/select",
    "GET",
    data
  );
};
// 智慧实训室 保养项目添加
const generalTrainingMaintenanceNodeinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceNode/insert",
    "POST",
    data
  );
};
// 智慧实训室 保养项目修改
const generalTrainingMaintenanceNodeupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceNode/update",
    "POST",
    data
  );
};
// 智慧实训室 保养项目删除
const generalTrainingMaintenanceNodedelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingMaintenanceNode/delete",
    "POST",
    data
  );
};
// 智慧实训室 保养任务
const generalTrainingTaskselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTask/select",
    "GET",
    data
  );
};
// 智慧实训室 设备对应计划
const noneedauthplans = (data: any) => {
  return request("/admin/generalTraining/noneedauth/plans", "GET", data);
};
// 智慧实训室 实训室下拉
const noneedauthtrainings = (data: any) => {
  return request("/admin/generalTraining/noneedauth/trainings", "GET", data);
};
// 智慧实训室 设备类型下拉
const noneedauthdeviceCates = (data: any) => {
  return request("/admin/generalTraining/noneedauth/deviceCates", "GET", data);
};
// 智慧实训室 设备类型对应保养类型
const noneedauthmaintenanceType = (data: any) => {
  return request(
    "/admin/generalTraining/noneedauth/maintenanceType",
    "GET",
    data
  );
};
// 智慧实训室 保养类型对应保养项目
const noneedauthmaintenanceNode = (data: any) => {
  return request(
    "/admin/generalTraining/noneedauth/maintenanceNode",
    "GET",
    data
  );
};
// 智慧实训室 所有设备
const generalTrainingnoneedauthdevices = (data: any) => {
  return request("/admin/generalTraining/noneedauth/devices", "GET", data);
};
// 智慧实训室 柜组下拉
const noneedauthcabinetGroups = (data: any) => {
  return request(
    "/admin/generalTraining/noneedauth/cabinetGroups",
    "GET",
    data
  );
};
// 智慧实训室 工具类型下拉
const noneedauthlibCategorys = (data: any) => {
  return request("/admin/generalTraining/noneedauth/libCategorys", "GET", data);
};
// 智慧实训室 故障报修列表
const generalTrainingDeviceFaultselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/select",
    "GET",
    data
  );
};
// 智慧实训室 工具柜组管理列表
const generalTrainingCabinetGroupselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetGroup/select",
    "GET",
    data
  );
};
// 智慧实训室 工具柜组修改
const generalTrainingCabinetGroupupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetGroup/update",
    "POST",
    data
  );
};
// 智慧实训室 工具柜列表
const generalTrainingCabinetselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/select",
    "GET",
    data
  );
};
// 智慧实训室 工具柜修改
const generalTrainingCabinetupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/update",
    "POST",
    data
  );
};
// 智慧实训室 工具库管理类型列表
const generalTrainingLibCategoryselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLibCategory/select",
    "GET",
    data
  );
};
// 智慧实训室 工具库管理类型添加
const generalTrainingLibCategoryinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLibCategory/insert",
    "POST",
    data
  );
};
// 智慧实训室 工具库管理类型修改
const generalTrainingLibCategoryupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLibCategory/update",
    "POST",
    data
  );
};
// 智慧实训室 工具库管理类型删除
const generalTrainingLibCategorydelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLibCategory/delete",
    "POST",
    data
  );
};
// 智慧实训室 工具管理列表
const generalTrainingLibselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLib/select",
    "GET",
    data
  );
};
// 智慧实训室 工具管理添加
const generalTrainingLibinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLib/insert",
    "POST",
    data
  );
};
// 智慧实训室 工具管理修改
const generalTrainingLibupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLib/update",
    "POST",
    data
  );
};
// 智慧实训室 工具管理删除
const generalTrainingLibdelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingLib/delete",
    "POST",
    data
  );
};
// 智慧实训室 人脸同步列表
const generalTrainingCabinetTaskselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetTask/select",
    "GET",
    data
  );
};
// 智慧实训室 部门下拉
const generalTrainingnoneedauthorgs = (data: any) => {
  return request("/admin/generalTraining/noneedauth/orgs", "GET", data);
};
// 智慧实训室 同步人脸
const generalTrainingCabinetTaskinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetTask/insert",
    "POST",
    data
  );
};
// 智慧实训室 故障报修添加
const generalTrainingDeviceFaultinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/insert",
    "POST",
    data
  );
};
// 智慧实训室 故障报修删除
const generalTrainingDeviceFaultdelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/delete",
    "POST",
    data
  );
};
// 智慧实训室 故障报修撤销
const generalTrainingDeviceFaultcancel = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/cancel",
    "GET",
    data
  );
};
// 智慧实训室 维修记录列表
const generalTrainingDeviceFaultLogsselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultLogs/select",
    "GET",
    data
  );
};
// 智慧实训室 故障报修上报列表
const generalTrainingDeviceFaultHandleselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/select",
    "GET",
    data
  );
};
// 智慧实训室 故障报修上报列表
const generalTrainingDeviceFaultHandleupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/update",
    "POST",
    data
  );
};
// 智慧实训室 故障报修处理维修单
const generalTrainingDeviceFaultHandlehandle = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/handle",
    "POST",
    data
  );
};
// 出入记录
const dormGateOldRecordselect = (data: any) => {
  return request("/admin/dorm/dormGateOldRecord/select", "GET", data);
};
// 智慧实训室 房间下拉
const noneedauthrooms = (data: any) => {
  return request("/admin/generalTraining/noneedauth/rooms", "GET", data);
};
// 智慧实训室 保养任务修改
const generalTrainingTaskupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTask/update",
    "POST",
    data
  );
};
// 智慧实训室 保养任务修改
const generalTrainingdeviceWarnselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingdeviceWarn/select",
    "GET",
    data
  );
};
// 智慧实训室 所有工具
const noneedauthlibs = (data: any) => {
  return request("/admin/generalTraining/noneedauth/libs", "GET", data);
};
// 智慧实训室 工具入柜
const generalTrainingCabinetinLib = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/inLib",
    "GET",
    data
  );
};
// 智慧实训室 工具入柜工具列表
const generalTrainingCabinetlibList = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/libList",
    "GET",
    data
  );
};
// 智慧实训室 工具入柜借还记录
const generalTrainingCabinetlogs = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/logs",
    "GET",
    data
  );
};
// 智慧实训室 工具入柜工具列表删除
const generalTrainingCabinetdeleteGood = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinet/deleteGood",
    "POST",
    data
  );
};
// 智慧实训室 人脸信息
const generalTrainingCabinetGroupdetail = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetGroup/detail",
    "GET",
    data
  );
};
// 智慧实训室 人脸信息删除
const generalTrainingCabinetGroupdeleteFase = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingCabinetGroup/deleteFase",
    "POST",
    data
  );
};
// 归寝管理 寝室归寝明细
const DormAttendanceLogselect = (data: any) => {
  return request("/admin/dorm/DormAttendanceLog/select", "GET", data);
};
// 智慧实训室 保养记录
const generalTrainingTaskLogsselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTaskLogs/select",
    "GET",
    data
  );
};
// 实训室数据大屏 实验室信息
const statisticstrainings = (data: any) => {
  return request("/admin/generalTraining/statistics/trainings", "GET", data);
};
// 实训室数据大屏 报修数据
const statisticsfaults = (data: any) => {
  return request("/admin/generalTraining/statistics/faults", "GET", data);
};
// 实训室数据大屏 设备列表
const deviceListe = (data: any) => {
  return request("/admin/generalTraining/statistics/deviceList", "GET", data);
};
// 实训室数据大屏 实训室排课top
const courseTop = (data: any) => {
  return request("/admin/generalTraining/statistics/courseTop", "GET", data);
};
// 实训室数据大屏 保养数据
const statisticsmaintenances = (data: any) => {
  return request("/admin/generalTraining/statistics/maintenances", "GET", data);
};
// 实训室数据大屏 预警数据
const statisticswarns = (data: any) => {
  return request("/admin/generalTraining/statistics/warns", "GET", data);
};
// 实训室数据大屏 设备数量统计
const statisticsdevices = (data: any) => {
  return request("/admin/generalTraining/statistics/devices", "GET", data);
};

// 实训室信息
const trainingsselect = (data: any) => {
  return request("/admin/generalTraining/statistics/trainings", "GET", data);
};
// 保养数据
const maintenancese = (data: any) => {
  return request("/admin/generalTraining/statistics/maintenances", "GET", data);
};
//智慧实训室  获取设备状态
const generalTrainingDevicegetDeviceStatus = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/getDeviceStatus",
    "GET",
    data
  );
};
//智慧实训室  设置设备状态
const generalTrainingDevicesetDeviceStatus = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/setDeviceStatus",
    "GET",
    data
  );
};
//智慧实训室  采购申请
const generalDeviceFaultPurchaseselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/select",
    "GET",
    data
  );
};
//智慧实训室  采购申请 取消申请
const generalDeviceFaultPurchaseupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/update",
    "POST",
    data
  );
};
//智慧实训室  采购申请 配件取消
const generalDeviceFaultPurchasegood = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/good",
    "GET",
    data
  );
};
//智慧实训室  采购审批 列表
const GeneralDeviceFaultPurchaseAuditselect = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseAudit/select",
    "GET",
    data
  );
};
//智慧实训室  采购审批 审批
const GeneralDeviceFaultPurchaseAuditupdate = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseAudit/update",
    "POST",
    data
  );
};
//智慧实训室  采购清单列表
const GeneralDeviceFaultPurchaseGoodselect = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseGood/select",
    "GET",
    data
  );
};
//智慧实训室  采购清单采购
const GeneralDeviceFaultPurchaseGoodupdate = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseGood/update",
    "POST",
    data
  );
};
//智慧实训室  实训室日历or排课
const statisticscourseTable = (data: any) => {
  return request("/admin/generalTraining/statistics/courseTable", "GET", data);
};
//智慧实训室  实训室维修统计
const statisticsmyRepair = (data: any) => {
  return request("/admin/generalTraining/statistics/myRepair", "POST", data);
};
//智慧实训室  实训室保养统计
const statisticsmyMainten = (data: any) => {
  return request("/admin/generalTraining/statistics/myMainten", "POST", data);
};
//智慧实训室  实训室详情
const statisticstrainingInfo = (data: any) => {
  return request("/admin/generalTraining/statistics/trainingInfo", "GET", data);
};
//仓储管理 数据统计
const warehousestatisselect = (data: any) => {
  return request("/admin/warehouse/warehousestatis/select", "GET", data);
};
// 智慧实训室 楼栋下拉
const generalTrainingnoneedauthbuildings = (data: any) => {
  return request("/admin/generalTraining/noneedauth/buildings", "GET", data);
};
// 仓储管理 盘点记录列表
const WarehouseInventoryLogselect = (data: any) => {
  return request("/admin/warehouse/WarehouseInventoryLog/select", "GET", data);
};
// 仓储管理 盘点记录详情列表
const WarehouseInventoryLogdetail = (data: any) => {
  return request("/admin/warehouse/WarehouseInventoryLog/detail", "GET", data);
};
// 仓储管理 盘点记录详情修改
const WarehouseInventoryLogupdate = (data: any) => {
  return request("/admin/warehouse/WarehouseInventoryLog/update", "POST", data);
};
// 门禁管理
const GeneralTrainingGateselect = (data: any) => {
  return request(
    "/admin/generaltraining/GeneralTrainingGate/select",
    "GET",
    data
  );
};
// 门禁管理 开门
const GeneralTrainingGateupdate = (data: any) => {
  return request(
    "/admin/generaltraining/GeneralTrainingGate/update",
    "POST",
    data
  );
};
// 门禁管理 人员列表
const GeneralTrainingGateuserList = (data: any) => {
  return request(
    "/admin/generaltraining/GeneralTrainingGate/userList",
    "GET",
    data
  );
};
// AI评分 首页
const hanjiestatisticsindext = (data: any) => {
  return request("/admin/hanjie/statistics/index", "GET", data);
};
// AI评分 所有实训室下拉数据
const hanjienoNeedAuthtrainings = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/trainings", "GET", data);
};
// AI管理 实训室管理 列表
const hanjietrainingselect = (data: any) => {
  return request("/admin/hanjie/training/select", "GET", data);
};
// AI管理 实训室管理 添加
const hanjietraininginsert = (data: any) => {
  return request("/admin/hanjie/training/insert", "POST", data);
};
// AI管理 实训室管理 修改
const hanjietrainingupdate = (data: any) => {
  return request("/admin/hanjie/training/update", "POST", data);
};
// AI管理 实训室管理 删除
const hanjietrainingdelete = (data: any) => {
  return request("/admin/hanjie/training/delete", "POST", data);
};
// AI管理 所有房间
const hanjienoNeedAuthrooms = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/rooms", "GET", data);
};
// AI管理 摄像头 列表
const hanjiecameraselect = (data: any) => {
  return request("/admin/hanjie/camera/select", "GET", data);
};
// AI管理 摄像头 添加
const hanjiecamerainsert = (data: any) => {
  return request("/admin/hanjie/camera/insert", "POST", data);
};
// AI管理 摄像头 修改
const hanjiecameraupdate = (data: any) => {
  return request("/admin/hanjie/camera/update", "POST", data);
};
// AI管理 工位下拉
const hanjienoNeedAuthpositions = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/positions", "GET", data);
};
// AI评分 班牌列表
const aicardselect = (data: any) => {
  return request("/admin/hanjie/ecard/select", "GET", data);
};
// AI评分 工位管理 列表
const aipositionselect = (data: any) => {
  return request("/admin/hanjie/position/select", "GET", data);
};
// AI评分 工位管理 添加
const aipositioninsert = (data: any) => {
  return request("/admin/hanjie/position/insert", "POST", data);
};
// AI评分 工位管理 修改
const aipositionupdate = (data: any) => {
  return request("/admin/hanjie/position/update", "POST", data);
};
// AI评分 工位管理 删除
const aipositiondelete = (data: any) => {
  return request("/admin/hanjie/position/delete", "POST", data);
};
// AI评分 工位管理 解绑设备
const aipositionunbind = (data: any) => {
  return request("/admin/hanjie/position/unbind", "GET", data);
};
// AI评分 工位管理 获取所有工位
const aipositionsselect = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/positions", "GET", data);
};
// AI评分 工艺管理 列表
const hanjietechnologyselect = (data: any) => {
  return request("/admin/hanjie/technology/select", "GET", data);
};
// AI评分 工艺管理 添加
const hanjietechnologyinsert = (data: any) => {
  return request("/admin/hanjie/technology/insert", "POST", data);
};
// AI评分 工艺管理 修改
const hanjietechnologyupdate = (data: any) => {
  return request("/admin/hanjie/technology/update", "POST", data);
};
// AI评分 工艺管理 删除
const hanjietechnologydelete = (data: any) => {
  return request("/admin/hanjie/technology/delete", "POST", data);
};
// AI评分 banner设置 列表
const bannerselect = (data: any) => {
  return request("/admin/hanjie/banner/select", "GET", data);
};
// AI评分 banner设置 添加
const bannerinsert = (data: any) => {
  return request("/admin/hanjie/banner/insert", "POST", data);
};
// AI评分 banner设置 修改
const bannerupdate = (data: any) => {
  return request("/admin/hanjie/banner/update", "POST", data);
};
// AI评分 banner设置 删除
const bannerdelete = (data: any) => {
  return request("/admin/hanjie/banner/delete", "POST", data);
};
// AI评分 任务公单 列表
const hanjietaskselect = (data: any) => {
  return request("/admin/hanjie/task/select", "GET", data);
};
// AI评分 任务公单 发布任务
const hanjietaskpush = (data: any) => {
  return request("/admin/hanjie/task/push", "POST", data);
};
// AI评分 任务公单 添加
const hanjietaskinsert = (data: any) => {
  return request("/admin/hanjie/task/insert", "POST", data);
};
// AI评分 任务公单 修改
const hanjietaskupdate = (data: any) => {
  return request("/admin/hanjie/task/update", "POST", data);
};
// AI评分 任务公单 删除
const hanjietaskdelete = (data: any) => {
  return request("/admin/hanjie/task/delete", "POST", data);
};
// AI评分 ai项目组下拉数据
const hanjienoneedauthscoreGroup = (data: any) => {
  return request("/admin/hanjie/noneedauth/scoreGroup", "GET", data);
};
// AI评分 评分项目组 列表
const hanjieaiScoreGroupselect = (data: any) => {
  return request("/admin/hanjie/aiScoreGroup/select", "GET", data);
};
// AI评分 评分项目组 添加
const hanjieaiScoreGroupinsert = (data: any) => {
  return request("/admin/hanjie/aiScoreGroup/insert", "POST", data);
};
// AI评分 评分项目组 修改
const hanjieaiScoreGroupupdate = (data: any) => {
  return request("/admin/hanjie/aiScoreGroup/update", "POST", data);
};
// AI评分 评分项目组 删除
const hanjieaiScoreGroupdelete = (data: any) => {
  return request("/admin/hanjie/aiScoreGroup/delete", "POST", data);
};
// AI评分 评分项下拉框数据
const hanjienoNeedAuthscoreNodes = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/scoreNodes", "GET", data);
};
// AI评分 设备列表
const trainingDeviceselect = (data: any) => {
  return request("/admin/hanjie/trainingDevice/select", "GET", data);
};
// AI评分 设备添加
const trainingDeviceinsert = (data: any) => {
  return request("/admin/hanjie/trainingDevice/insert", "POST", data);
};
// AI评分 设备修改
const trainingDeviceupdate = (data: any) => {
  return request("/admin/hanjie/trainingDevice/update", "POST", data);
};
// AI评分 设备刪除
const trainingDevicedelete = (data: any) => {
  return request("/admin/hanjie/trainingDevice/delete", "POST", data);
};
// AI评分 班级任务管理 列表
const taskClassselect = (data: any) => {
  return request("/admin/hanjie/taskClass/select", "GET", data);
};
// AI评分 视频记录
const hanjievideoLogselect = (data: any) => {
  return request("/admin/hanjie/videoLog/select", "GET", data);
};
// AI评分 班级下拉数据
const hanjienoNeedAuthclasses = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/classes", "GET", data);
};
// AI评分 任务下拉
const hanjienoneedauthtasks = (data: any) => {
  return request("/admin/hanjie/noneedauth/tasks", "GET", data);
};
// AI评分 场次下拉数据
const hanjienoNeedAuthclassNodes = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/classNodes", "GET", data);
};

// AI评分 成绩管理 列表
const ResultLogelect = (data: any) => {
  return request("/admin/hanjie/ResultLog/select", "GET", data);
};
// AI评分 成绩管理 修改成绩
const ResultLogupdate = (data: any) => {
  return request("/admin/hanjie/resultLog/update", "POST", data);
};
// AI评分 工艺品下拉数据
const hanjienoNeedAuthtechnologys = (data: any) => {
  return request("/admin/hanjie/noNeedAuth/technologys", "GET", data);
};
// AI评分 班级任务列表
const taskClassseloect = (data: any) => {
  return request("/admin/hanjie/taskClass/select", "GET", data);
};
// AI评分 考试记录详情
const taskclasslogs = (data: any) => {
  return request("/admin/hanjie/taskclass/logs", "GET", data);
};
// AI评分 场次下拉数据
const hanjienoneedauthtaskNodes = (data: any) => {
  return request("/admin/hanjie/noneedauth/taskNodes", "GET", data);
};
// AI评分 电压电流echarts数据
const taskclassechart = (data: any) => {
  return request("/admin/hanjie/taskclass/echart", "GET", data);
};
// AI评分 考试实训室/工位摄像头地址
const taskclassvideo = (data: any) => {
  return request("/admin/hanjie/taskclass/video", "GET", data);
};
// AI评分 成绩管理 学生成绩
const hanjieresultLogresult = (data: any) => {
  return request("/admin/hanjie/resultLog/result", "GET", data);
};
// AI评分 成绩管理 分明细
const hanjieresultLogscore = (data: any) => {
  return request("/admin/hanjie/resultLog/score", "GET", data);
};
// AI评分 考试实训室/工位摄像头地址
const taskClassupdate = (data: any) => {
  return request("/admin/hanjie/taskClass/update", "POST", data);
};
// AI评分 考试记录详情
const taskclassdetail = (data: any) => {
  return request("/admin/hanjie/taskclass/detail", "GET", data);
};
// AI评分 任务发布记录
const taskClassselects = (data: any) => {
  return request("/admin/hanjie/taskClass/select", "GET", data);
};
// AI评分 班级任务取消
const taskClasschannel = (data: any) => {
  return request("/admin/hanjie/taskClass/channel", "GET", data);
};
// AI评分 查看成绩
const taskclassscore = (data: any) => {
  return request("/admin/hanjie/taskclass/score", "GET", data);
};
// 资源管理 资源列表
const resourcedirectoryselect = (data: any) => {
  return request("/admin/resource/directory/select", "GET", data);
};
// 资源管理 所有文件
const resourcefileselect = (data: any) => {
  return request("/admin/resource/file/select", "GET", data);
};
// 资源管理 添加目录
const resourcedirectoryinsert = (data: any) => {
  return request("/admin/resource/directory/insert", "POST", data);
};
// 资源管理 修改目录
const resourcedirectoryupdate = (data: any) => {
  return request("/admin/resource/directory/update", "POST", data);
};
// 资源管理 删除目录
const resourcedirectorydelete = (data: any) => {
  return request("/admin/resource/directory/delete", "POST", data);
};
// 资源管理 添加文件
const resourcefileinsert = (data: any) => {
  return request("/admin/resource/file/insert", "POST", data);
};
// 资源管理  修改文件
const resourcefileupdate = (data: any) => {
  return request("/admin/resource/file/update", "POST", data);
};
// 资源管理  删除文件
const resourcefiledelete = (data: any) => {
  return request("/admin/resource/file/delete", "POST", data);
};
// 资源管理 上传文件
const resourceuploadfile = (data: any) => {
  return request("/admin/resource/upload/file", "POST", data);
};

export {
  resourcefiledelete,
  resourcefileupdate,
  resourcedirectorydelete,
  resourcedirectoryupdate,
  resourceuploadfile,
  resourcefileinsert,
  resourcedirectoryinsert,
  resourcefileselect,
  resourcedirectoryselect,
  taskclassscore,
  taskClasschannel,
  taskClassselects,
  taskclassdetail,
  hanjieresultLogscore,
  hanjieresultLogresult,
  taskClassupdate,
  taskclassvideo,
  hanjienoneedauthtaskNodes,
  taskclassechart,
  taskclasslogs,
  taskClassseloect,
  hanjienoNeedAuthtechnologys,
  hanjienoneedauthtasks,
  hanjienoNeedAuthclassNodes,
  hanjienoNeedAuthclasses,
  hanjievideoLogselect,
  ResultLogelect,
  ResultLogupdate,
  hanjienoNeedAuthscoreNodes,
  hanjieaiScoreGroupdelete,
  hanjieaiScoreGroupupdate,
  hanjieaiScoreGroupinsert,
  hanjieaiScoreGroupselect,
  taskClassselect,
  trainingDeviceselect,
  trainingDeviceinsert,
  trainingDeviceupdate,
  trainingDevicedelete,
  hanjienoneedauthscoreGroup,
  hanjietaskdelete,
  hanjietaskupdate,
  hanjietaskinsert,
  hanjietaskselect,
  hanjietaskpush,
  aipositionunbind,
  hanjietechnologydelete,
  hanjietechnologyupdate,
  hanjietechnologyinsert,
  hanjietechnologyselect,
  bannerselect,
  bannerinsert,
  bannerupdate,
  bannerdelete,
  hanjienoNeedAuthpositions,
  hanjiecameraupdate,
  hanjiecamerainsert,
  hanjiecameraselect,
  aipositiondelete,
  aipositioninsert,
  aipositionupdate,
  aipositionsselect,
  aipositionselect,
  hanjietrainingdelete,
  hanjietrainingupdate,
  hanjienoNeedAuthrooms,
  hanjietraininginsert,
  hanjietrainingselect,
  aicardselect,
  hanjienoNeedAuthtrainings,
  hanjiestatisticsindext,
  GeneralTrainingGateuserList,
  GeneralTrainingGateupdate,
  GeneralTrainingGateselect,
  wechat,
  scanLogin,
  WarehouseInventoryLogupdate,
  WarehouseInventoryLogdetail,
  WarehouseInventoryLogselect,
  generalTrainingnoneedauthbuildings,
  warehousestatisselect,
  authnoNeedAuthadmins,
  noNeedAuthadmins,
  authnoneedauthorgs,
  statisticstrainingInfo,
  statisticsmyRepair,
  statisticsmyMainten,
  statisticscourseTable,
  warehousecabinetlogstatisselect,
  GeneralDeviceFaultPurchaseAuditupdate,
  GeneralDeviceFaultPurchaseAuditselect,
  GeneralDeviceFaultPurchaseGoodupdate,
  GeneralDeviceFaultPurchaseGoodselect,
  generalDeviceFaultPurchasegood,
  generalDeviceFaultPurchaseupdate,
  generalDeviceFaultPurchaseselect,
  generalTrainingDevicesetDeviceStatus,
  generalTrainingDevicegetDeviceStatus,
  statisticsdevices,
  statisticswarns,
  statisticsmaintenances,
  statisticstrainings,
  maintenancese,
  trainingsselect,
  statisticsfaults,
  generalTrainingTaskLogsselect,
  DormAttendanceLogselect,
  generalTrainingCabinetGroupdeleteFase,
  generalTrainingCabinetGroupdetail,
  generalTrainingCabinetdeleteGood,
  generalTrainingCabinetlogs,
  generalTrainingCabinetlibList,
  generalTrainingCabinetinLib,
  noneedauthlibs,
  generalTrainingdeviceWarnselect,
  generalTrainingTaskupdate,
  noneedauthrooms,
  dormGateOldRecordselect,
  generalTrainingDeviceFaultHandlehandle,
  generalTrainingDeviceFaultHandleupdate,
  generalTrainingDeviceFaultHandleselect,
  generalTrainingDeviceFaultLogsselect,
  generalTrainingDeviceFaultcancel,
  generalTrainingDeviceFaultdelete,
  generalTrainingDeviceFaultinsert,
  noneedauthplans,
  generalTrainingCabinetTaskinsert,
  generalTrainingnoneedauthorgs,
  generalTrainingCabinetTaskselect,
  generalTrainingLibdelete,
  generalTrainingLibupdate,
  generalTrainingLibinsert,
  noneedauthlibCategorys,
  generalTrainingLibselect,
  generalTrainingLibCategorydelete,
  generalTrainingLibCategoryupdate,
  generalTrainingLibCategoryinsert,
  generalTrainingLibCategoryselect,
  generalTrainingCabinetupdate,
  generalTrainingCabinetselect,
  noneedauthcabinetGroups,
  generalTrainingCabinetGroupupdate,
  generalTrainingCabinetGroupselect,
  generalTrainingnoneedauthdevices,
  generalTrainingDeviceFaultselect,
  noneedauthmaintenanceNode,
  noneedauthmaintenanceType,
  noneedauthdeviceCates,
  noneedauthtrainings,
  generalTrainingTaskselect,
  generalTrainingMaintenanceNodedelete,
  generalTrainingMaintenanceNodeupdate,
  generalTrainingMaintenanceNodeinsert,
  generalTrainingMaintenanceNodeselect,
  generalTrainingPlandelete,
  generalTrainingPlanupdate,
  generalTrainingPlaninsert,
  generalTrainingPlanselect,
  generalTrainingMaintenanceTypedelete,
  generalTrainingMaintenanceTypeupdate,
  generalTrainingMaintenanceTypeinsert,
  generalTrainingMaintenanceTypeselect,
  generalTrainingDeviceCatedelete,
  generalTrainingDeviceCateupdate,
  generalTrainingDeviceCateinsert,
  generalTrainingDeviceCateselect,
  generalTrainingTypedelete,
  generalTrainingTypeupdate,
  generalTrainingTypeinsert,
  generalTrainingTypeselect,
  generalTrainingnoneedauthtrainingType,
  generalTrainingDevicedelete,
  generalTrainingDeviceupdate,
  generalTrainingDeviceinsert,
  generalTrainingdelete,
  generalTrainingselect,
  generalTraininginsert,
  generalTrainingupdate,
  generalTrainingDeviceselect,
  dormGateOldselect,
  dormGateOldupdate,
  dormGateOlddoor,
  dormGateOldopenAll,
  dormGateOldcloseAlll,
  dormGateOldmembers,
  noneedauthqcGates,
  dormGateOldsync,
  dormGateOldgateImage,
  dormGateOlddelImage,
  dormGateOldcrontabList,
  dormGateOldcrontabCreate,
  dormGateOldcrontabUpdate,
  dormGateOldcrontabDelete,
  dormGateOldcrontabStatus,
  warehousecabinetordercountselect,
  noneedauthprojecting,
  tuyasencerulepooldelete,
  noneedauthtuyaRooms,
  statisticquantitydevice,
  statisticquantity,
  statistictop,
  statisticswarn,
  statisticsstatus,
  statisticscount,
  noneedauthconditionDevices,
  tuyasencerulepoolactiondelete,
  tuyasencerulepoolactionupdate,
  tuyasencerulepoolactionrooms,
  tuyasencerulepoolupdate,
  tuyasencerulepoolinsert,
  tuyasencerulepoolactioninsert,
  tuyasencerulepoolactionselect,
  tuyasencerulepoolselect,
  tuyahomeroomswitch,
  tuyahomeroomlist,
  tuyahomeroomdetail,
  selectClassesOrg,
  tuyadevicestatistics,
  tuyadeviceselect,
  tuyawarnsetupdate,
  tuyawarnsetdelete,
  tuyawarnsetinsert,
  tuyawarnsetselect,
  tuyawarnselect,
  tuyahomeroomselect,
  noneedauthdevices,
  noneedauthcategorys,
  noneedauthbuildings,
  noneedauthprojects,
  noneedauthorgs,
  warehouseprojectselect,
  warehouseprojectinsert,
  warehouseprojectupdate,
  warehouseprojectdelete,
  NoNeedAuthNoNeedAuth,
  ecardtaskdelete,
  statisticsleaveStudents,
  EduTeacherSignselect,
  outdormselect,
  gqselectdatalist,
  kqselectdatalist,
  allpagehomeselect,
  noneedauthdevicePosition,
  dormdevicepositionupdate,
  dormdevicepositiondelete,
  dormdevicepositioninsert,
  dormdevicepositionselect,
  noneedauthtree,
  kqstatisticsmonthdetail,
  kqstatisticsmonthselect,
  kqkqstatisticstop,
  kqkqstatisticsdep,
  kqkqstatisticsmounth,
  kqkqstatisticsdetail,
  kqkqstatistics,
  kqnoneedauthadmins,
  kqleaveruleinsert,
  kqleaveruleupdate,
  kqstatisticsdayslelct,
  kquncardapplymyauditdetail,
  kquncardapplymyauditupdate,
  kquncardapplymyauditselect,
  kquncardapplymydelete,
  kquncardapplymyinsert,
  kqleaveapplymyauditselect,
  kqleaveapplymyauditstatus,
  kqleaveapplymyauditauditList,
  kquncardapplymyselect,
  myleaveinsert,
  myleavestatus,
  kqleaveruleselect,
  kqleaverulestatus,
  kqleaveruledelete,
  kquncardruleselect,
  kquncardrulestatus,
  kquncardruledelete,
  kqkqworkruleupdate,
  myleaveselect,
  replacementlog,
  kqkqworkruleinsert,
  kqnoneedautheq,
  kqnoneedauthorgs,
  allorgsselect,
  kqleaveapplyselect,
  kqequpdate,
  kqeqselect,
  kqkqworkruleselect,
  kqkqworkruledelete,
  kqkqworkrulestatus,
  educoursetermInfo,
  dormDormAttendanceselect,
  dormDormAttendancedetail,
  dormdormmembeselectr,
  dormdormquitselect,
  dormdormquitdelete,
  dormdormarrangestudents,
  dormdormarrangestudentSave,
  dormdormarrangeselect,
  dormnoneedauthgrades,
  dormnoneedauthclasses,
  dormnoneedauthdorms,
  dormnoneedauthinsert,
  dordormbedselect,
  dordormbedinsert,
  dordormbeddelete,
  dormdormgateselect,
  dormdevicereleasedelete,
  dormdevicereleaseupdate,
  dormdormnotifyselect,
  dormdormnotifyinsert,
  dormdormnotifyupdate,
  dormdormnotifydelete,
  dormdormtypeselect,
  dormdormtypeinsert,
  dormdormtypedelete,
  dormdormtypeupdate,
  dormdormselect,
  dormdorminsert,
  dormdormdelete,
  dormdormupdate,
  noneedauthadmins,
  dormdormBuildings,
  dormnoneedauthdormType,
  dormbuildingselect,
  dormbuildinginsert,
  dormbuildingdelete,
  dormbuildingupdate,
  eduedusubjectselect,
  eduedusubjectdelete,
  eduedusubjectinsert,
  eduedusubjectupdate,
  edustudentleaveselect,
  edustudentleavlogselect,
  edustudentleaveupdate,
  trainingtrainingdeviceselect,
  trainingtrainingdevicedelete,
  trainingtrainingdeviceinsert,
  trainingtrainingdeviceupdate,
  trainingtrainingdevicedetail,
  trainingtrainingdevicegetDeviceStatus,
  trainingtrainingdevicesetDeviceStatus,
  trainingtrainingselect,
  trainingdevicewarnlogselect,
  trainingnoneedauthselect,
  trainingroomsselect,
  trainingtraininginsert,
  trainingtrainingdelete,
  trainingtrainingupdate,
  ecardselect,
  ecardupdateface,
  ecartaskselect,
  ecartaskdelete,
  ecarcronselect,
  ecardneedbuildingselect,
  ecardneedroomselect,
  ecardneedselectecard,
  ecarcroninsert,
  ecarcrondelete,
  ecarcronupdateState,
  ecarcronupdate,
  ecartaskselectface,
  ecarauthselectclasses,
  ecartaskinsertface,
  ecardupdateState,
  edutimetablesupdate,
  eduneedAuthcourse,
  eduneedroomtree,
  eduCoursetableinsert,
  edutimetableselect,
  edutimetableupdate,
  edutimetableselectset,
  edutimetablesinsert,
  eduAuthsemester,
  eduAuthclasses,
  eduAuthadminorg,
  eduAuthteacher,
  eduAuthgrade,
  eduCourseselect,
  eduCoursetableselect,
  eduneedAuthclasses,
  coursetledetail,
  courSelect,
  courInsert,
  courDelete,
  courUpdate,
  authsubject,
  coursetleselectday,
  coursetableselect,
  coursetleselect,
  disableUpdate,
  disableSelect,
  disableInsert,
  disableDelete,
  disableUpdatesta,
  systemSelect,
  systemInsert,
  systemUpadate,
  systemDelete,
  attendanceSelect,
  attendanceDetail,
  allendanceDetail,
  detailDetail,
  detailUpdate,
  screensaverselect,
  screensaverInsert,
  screensaverDelete,
  screensaverUpdatesta,
  screensaverMove,
  screensaverUpdate,
  studentWorksselect,
  studentWorksadd,
  studentWorksdelete,
  studentWorksupdatesta,
  studentWorksupdate,
  ecardClassselect,
  ecardClassadd,
  studentSelect,
  ecardClassdelete,
  ecardClassupdatesta,
  ecardClassupdate,
  Newsselect,
  Newsadd,
  Newsdelete,
  Newsupdatesta,
  Newsupdate,
  schoolNewsselect,
  schoolNewsadd,
  schoolNewsdelete,
  schoolNewsupdatesta,
  schoolNewsupdate,
  ecardNewsselect,
  ecardNewsadd,
  ecardNewsclassselect,
  ecardNewsadelete,
  ecardNewsaupdate,
  ecardupdate,
  schoolecardNewsselect,
  schoolecardNewsadd,
  schoolecardNewsadelete,
  schoolecardNewsaupdate,
  schoolecardupdate,
  checkcode,
  picturecode,
  noticemanage,
  noticeinsert,
  noticeupdate,
  noticedelete,
  circulateLog,
  inventoryAlert,
  terminalface,
  allintools,
  wisdomRoom,
  wisdomRoomswitch,
  datestatistics,
  // statistics,
  facedetail,
  toolupdate,
  toolsupdate,
  gateupdate,
  allinventory,
  allytype,
  goodsDetails,
  getBuilding,
  toolsguanli,
  myapplyDetail,
  alllibsSelect,
  liblogSelect,
  warningSelect,
  myApprovelog,
  myreject,
  myApprove,
  myapplyUpdate,
  myapplyInsert,
  myapplySelect,
  applyUpdate,
  applyDelete,
  applyInsert,
  banjijiji1,
  classifys1,
  alladmin,
  applySelect,
  cjiaoshi3,
  bangonglou3,
  xuesheng3,
  banji3,
  daorumoban2,
  daorumoban3,
  daorumoban1,
  daorumoban,
  alltool,
  toolguanli,
  gaterecord,
  allgoodslib,
  allgroups,
  allgates,
  allwarehouses,
  goodsDelete,
  goodsUpdate,
  goodsInsert,
  goodsSelect,
  toolDelete,
  toolUpdate,
  toolInsert,
  toolSelect,
  classIfyDelete,
  classIfyUpdate,
  classIfyInsert,
  classIfySelect,
  gateSelect,
  warehouseDelete,
  warehouseUpdate,
  tupianshangc,
  zuzhijiagou,
  celuexiugai,
  celuetianjia,
  celueshanchu,
  celuexiangqing,
  celuelist,
  devicelist,
  warningLog,
  jiaoshihuanjing,
  jiaoshishebeiyunxing,
  jiaoshimianban,
  ketangyujing,
  ketangpaihang,
  ketangnengliang,
  ketangshebei,
  ketangshuJV,
  banjizhuangtai,
  banjizhuangtai1,
  banjiloudong,
  banjixiugai,
  banjixiangqing,
  banjishanchu,
  banjitianjia,
  banjishuJV,
  login,
  zhuhudaoru,
  zhuhushuJV,
  zhuhuxiugai,
  zhuhushanchu,
  zhuhutianjia,
  zhuhuzhuangtai,
  jigoushanchu,
  jigouxiugai,
  jigoutianjia,
  jigoushujv,
  zhuzhishanchu,
  zhuzhixiugai,
  zhuzhitianjia,
  zhuzhishujv,
  jiaoshixiangqing,
  jiaoshishanchu,
  jiaoshizhuangtai,
  jiaoshixiugai,
  jiaoshitianjia,
  jiaoshishuJV,
  jiaoxuexiangqing,
  jiaoxuetianjia,
  jiaoxuexiugai,
  jiaoxuezhuangtai,
  jiaoxueshanchu,
  jiaoxueshuJV,
  fangxiangtianjia,
  fangxaingxiugai,
  admin,
  insert,
  update,
  getUpdate,
  semesterDelete,
  semesterDetail,
  select,
  major,
  zhuanyefenlei,
  zhuanyetianjia,
  zhuanyeshanchu,
  zhuanyexiugai,
  zhuanyezhuangtai,
  fangxiangshanchu,
  zhaunyexiangqing,
  fangxiangxiangqing,
  grade,
  gradeupdate,
  gradeupdatee,
  gradedelete,
  gradeinsert,
  gradedetail,
  teacherSelect,
  teacherDelete,
  teachersaveState,
  teacherDetail,
  teacherInsert,
  studentselect,
  studentdelete,
  studentupdate,
  studentinsert,
  studentdetail,
  studentupdatee,
  teacherUpdatet,
  jueseguanli,
  juesetianjia,
  jueseshanchu,
  juesesxiugai,
  caidanmingxi,
  shouquanyonghu,
  shouquanyonghude,
  shouquanyonghuadd,
  huoqusuoyou,
  allpower,
  roomOpen,
  nenghaopaihang,
  permission,
  warehouseSelect,
  warehouseInsert,
  unlogin,
  multiFaceImage,
  uploadavatar,
  updateHeadImg,
  classifys,
  selectBuilding,
  adminOrg,
  teacherSelectauth,
  selectGrade,
  classify,
  passwsta,
  selectCtegoryGoods,
  moduleMenu,
  trainingRoom,
  newspasswd,
  informationUpdate,
  adminInfo,
  statisticsIndex,
  homePage,
  dimension,
  adminSelect,
  loginImg,
  systemnoticeSelect,
  systemnoticeUpdate,
  loginmobile,
  uploadFile,
  notifySelect,
  notifyInsert,
  notifyDeletet,
  notifyUpdate,
  semesterSelect,
  teacherWord,
  updayeTeacherWord,
  updayeTeacherupdateWord,
  main,
  getAdminInfo,
  deviceListe,
  courseTop,
};
