const configuration = {
  // 基础数据权限
  semester: "admin.basicdata.semester.select", //学期管理
  grade: "admin.basicdata.grade.select", //年级管理
  majorclassify: "admin.basicdata.majorclassify.select", //专业分类
  major: "admin.basicdata.major.select", //专业管理
  building: "admin.basicdata.building.select", //楼栋管理
  buildingroom: "admin.basicdata.buildingroom.select", //房间管理
  classes: "admin.basicdata.classes.select", //班级管理
  student: "admin.basicdata.student.select", //学生管理
  teacher: "admin.basicdata.teacher.select", //教师管理
  upload: "admin.basicdata.upload.select",
  // 用户管理权限
  admin: "admin.auth.admin.select", //登陆账户
  adminroles: "admin.auth.adminroles.select", //角色
  admindimension: "admin.auth.admindimension.select", //机构维度
  adminorg: "admin.auth.adminorg.select", //组织机构
  adminrolesbind: "admin.auth.adminrolesbind.select",
  // 智慧教室权限
  device: "admin.wisdom.device.select", //设备管理
  tuyahomeroom: "admin.tuya.tuyahomeroom.select", //智慧教室信息管理
  tuyawarnset: "admin.tuya.tuyawarnset.select", //预警规则管理
  tuyawarn: "admin.tuya.tuyawarn.select", //预警信息统计
  tuyasencerulepoolaction: "admin.tuya.tuyasencerulepoolaction.select", //执行管理
  tuyasencerulepool: "admin.tuya.tuyasencerulepool.select", //策略管理
  tuyadevice: "admin.tuya.tuyadevice.select", //设备管理
  //仓储管理权限
  warehousewarn: "admin.warehouse.warehousewarn.select", //异常预警
  warehousenotify: "admin.warehouse.warehousenotify.select", //公告管理
  warehouseproject: "admin.warehouse.warehouseproject.select", //项目管理
  warehouse: "admin.warehouse.warehouse.select", //仓库管理
  warehousecategory: "admin.warehouse.warehousecategory.select", //分类管理
  warehousegood: "admin.warehouse.warehousegood.select", //货物管理
  warehousegoodswarn: "admin.warehouse.warehousegoodswarn.select", //库存预警
  warehousecabinetorder: "admin.warehouse.warehousecabinetorder.select", //借还记录
  warehousecabinetlog: "admin.warehouse.warehousecabinetlogstatis.select", //退入库统计
  warehouseapply: "admin.warehouse.warehouseapply.select", //我的申请
  warehouseaudit: "admin.warehouse.warehouseaudit.select", //我的审核 审核管理
  warehouseworkflow: "admin.warehouse.warehouseworkflow.select", //申请流程管理
  warehouselib: "admin.warehouse.warehouselib.select", //物品库管理
  warehousecabinetgroup: "admin.warehouse.warehousecabinetgroup.select", //智能终端
  warehousecabinet: "admin.warehouse.warehousecabinet.select", //智能设备
  warehousegate: "admin.warehouse.warehousegate.select", //门禁管理
  warehousegatelog: "admin.warehouse.warehousegatelog.select", //通行记录
  warehousecabinetordercount:
    "admin.warehouse.warehousecabinetordercount.select", //出库统计
  warehousestatis: "admin.warehouse.warehousestatis.select", //数据统计
  warehouseinventorylog: "admin.warehouse.warehouseinventorylog.select", //盘点记录
  // 电子班牌
  ecardvideo: "admin.ecard.ecardvideo.select", //班级视频
  ecardnews: "admin.ecard.ecardnews.select", //班级风采
  classnotify: "admin.basicdata.classnotify.select", //通知公告
  ecardschoolvideo: "admin.ecard.ecardschoolvideo.select", //校园视频
  ecardschoolnews: "admin.ecard.ecardschoolnews.select", //校园风采
  ecardclassstu: "admin.ecard.ecardclassstu.select", //优秀学生
  ecardclassopus: "admin.ecard.ecardclassopus.select", //学生作品
  ecardscreenimage: "admin.ecard.ecardscreen.select", //图片屏保
  ecardscreenvideo: "admin.ecard.ecardscreen.select", //视频屏保
  ecardscreendisable: "admin.ecard.ecardscreendisable.select", //霸屏管理
  ecardnotify: "admin.ecard.ecardnotify.select", //系统公告
  ecard: "admin.ecard.ecard.select", //班牌管理
  // 教务中心
  eduattendance: "admin.edu.eduattendance.select", //全班考勤
  educoursetable: "admin.edu.educoursetable.select", //我的课表
  selectnowsemtableset: "admin.edu.educoursetable.select", //班级课表
  educourse: "admin.edu.educoursetableplan.select", //课程管理
  selectclass: "admin.edu.educoursetableplan.select", //排课
  edutimetableset: "admin.edu.edutimetableset.select", //作息时间维护
  dormstudentleave: "admin.edu.dormstudentleave.select", //请假管理
  edusubject: "admin.edu.edusubject.select", //学科管理
  eduteachersign: "admin.edu.eduteachersign.select", //班主任签到
  // 实训室
  training: "admin.training.training.select", //实训室管理
  trainingdevicewarnlog: "admin.training.trainingdevicewarnlog.select", //报警记录
  trainingdevice: "admin.training.trainingdevice.select", //设备管理
  // 归寝管理
  dormbuilding: "admin.dorm.dormbuilding.select", //楼栋信息
  dorm: "admin.dorm.dorm.select", //寝室管理
  dodormtypem: "admin.dorm.dormtype.select", //寝室类型
  dormbed: "admin.dorm.dormbed.select", //归寝床位管理
  dormarrange: "admin.dorm.dormarrange.select", //学生寝室安排
  dormquit: "admin.dorm.dormquit.select", // 退寝管理
  dormmember: "admin.dorm.dormmember.select", //寝室明细
  dormattendance: "admin.dorm.dormattendance.select", //寝室统计归寝
  dormattendancelog: "admin.dorm.dormattendancelog.select", //寝室归寝明细
  dormdeviceposition: "admin.dorm.dormdeviceposition.select", //设备位置管理
  dormdevicerelease: "admin.dorm.dormdevicerelease.select", //放型机管理
  dormgate: "admin.dorm.dormgate.select", //闸机管理
  dormGateOld: "admin.dorm.dormgateold.select", //设备管理
  dormnotify: "admin.dorm.dormnotify.select", //公告
  // 教职工
  kqleaveapplymy: "admin.kq.kqleaveapplymy.select", //我的请假
  kqleaveapplymyaudit: "admin.kq.kqleaveapplymyaudit.selec", //我的请假审核
  // 智慧实训室
  generaltraining: "admin.generaltraining.generaltraining.select", // 实训室管理
  generaltrainingdevicecate:
    "admin.generaltraining.generaltrainingdevicecate.select", // 设备类型
  generaltrainingdevice: "admin.generaltraining.generaltrainingdevice.select", // 设备管理
  generaltrainingdevicwarn:
    "admin.generaltraining.generaltrainingdevicewarn.select", // 设备预警
  generaltrainingmaintenancenode:
    "admin.generaltraining.generaltrainingmaintenancenode.select", // 保养类型
  generaltrainingplan: "admin.generaltraining.generaltrainingplan.select", // 保养计划
  generaltrainingtask: "admin.generaltraining.generaltrainingtask.select", // 保养任务
  generaltrainingtasklogs:
    "admin.generaltraining.generaltrainingtasklogs.select", // 保养记录
  generaltrainingdevicefault:
    "admin.generaltraining.generaltrainingdevicefault.select", // 故障报修
  generaltrainingdevicefaulthandle:
    "admin.generaltraining.generaltrainingdevicefaulthandle.select", // 维修上报
  generaltrainingdevicefaultlogs:
    "admin.generaltraining.generaltrainingdevicefaultlogs.select", // 维修记录
  generaltrainingcabinetgroup:
    "admin.generaltraining.generaltrainingcabinetgroup.select", // 智能工具柜管理
  generaltraininglib: "admin.generaltraining.generaltraininglib.select", // 工具库管理
  generaldevicefaultpurchase:
    "admin.generaltraining.generaldevicefaultpurchase.select", // 采购申请
  generaldevicefaultpurchasegood:
    "admin.generaltraining.generaldevicefaultpurchasegood.select", // 采购清单
  generaldevicefaultpurchaseaudit:
    "admin.generaltraining.generaldevicefaultpurchaseaudit.select", // 采购审批
  generaltraininggate: "admin.generaltraining.generaltraininggate.select", // 门禁管理
  // AI管理
  hanjietraining: "admin.hanjie.training.select", // 实训室管理
  hanjieposition: "admin.hanjie.position.select", // 工位管理
  hanjietrainingdevice: "admin.hanjie.trainingdevice.select", // 设备管理
  hanjieecard: "admin.hanjie.ecard.select", // 班牌管理
  hanjiecamera: "admin.hanjie.camera.select", // 摄像头管理
  hanjiebanner: "admin.hanjie.banner.select", // banner管理
  hanjietask: "admin.hanjie.task.select", // 任务管理
  hanjietechnology: "admin.hanjie.technology.select", // 工艺管理
  taskclass: "admin.hanjie.taskclass.select", //历史任务
  hanjieaiscoregroup: "admin.hanjie.aiscoregroup.select", // 评分标准
  hanjievideolog: "admin.hanjie.videolog.select", // 视频记录
  hanjieresultlog: "admin.hanjie.resultlog.select", // 成绩记录
};
export default configuration;
